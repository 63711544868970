import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./Search.css";
import { useNavigate } from "react-router-dom";

const Search = () => {
  const location = useLocation();
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  useEffect(() => {
    if (location?.state) {
      setSearchResults(location.state);
    }
  }, [location]);
  return searchResults.length > 0 ? (
    <div id="searchPage">
      <div className="wrapper">
        <h1>Shows</h1>
        <div className="searchDataContainer shows">
          {searchResults?.map((categorydata, index) => {
            if (categorydata?.type === "SHOWS") {
              return categorydata?.shows.map((showsData) => (
                <div
                  className="item"
                  key={index}
                  onClick={() => {
                    navigate({
                      pathname: "/show",
                      search: encodeURI(`show_id=${showsData.show_id}`),
                    });
                  }}
                >
                  <div className="imageContainer">
                    <img src={showsData?.logo_thumb} alt="" />
                  </div>
                  <h1 className="title">{showsData.show_name}</h1>
                </div>
              ));
            }
          })}
        </div>
        <h1>Episodes</h1>
        <div className="searchDataContainer">
          {searchResults?.map((categorydata, index) => {
            if (categorydata?.type === "VIDEOS") {
              return categorydata?.shows.map((showsData) => (
                <div
                  className="item"
                  key={index}
                  onClick={() => {
                    navigate({
                      pathname: `/player?video_ID=${showsData.video_id}`,
                    });
                  }}
                >
                  <div className="imageContainer">
                    <img src={showsData?.logo_thumb} alt="" />
                  </div>
                  <h1 className="title">{showsData.show_name}</h1>
                </div>
              ));
            }
          })}
        </div>
      </div>
    </div>
  ) : (
    <div id="searchPage">
      <div style={{ color: "white", textAlign: "center" }}>
        Sorry! No Results Found
      </div>
    </div>
  );
};

export default Search;
