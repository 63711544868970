import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { service } from "../../Network/Home/Service";

function Featured(param) {
  const [Shows, setShows] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    param && setShows(param.Data.shows);
  }, [param]);

  var settings2 = {
    dots: true,
    infinite: true,
    loop: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const addToMyList = (id, index) => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    let userId = service.getCookie("userId");
    if (isLoggedIn && userId) {
      service.addToMyPlayList(id, 1).then((response) => {
        if (response.success === true) {
          let showlist = [...Shows];
          showlist[index].watchlist_flag = 1;

          setShows(showlist);
        }
      });
    } else {
      navigate(`/login`);
    }
  };
  const removeFromMyList = (id, index) => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    let userId = service.getCookie("userId");
    if (isLoggedIn && userId) {
      service.addToMyPlayList(id, 0).then((response) => {
        if (response.success === true) {
          let showlist = [...Shows];
          showlist[index].watchlist_flag = 0;

          setShows(showlist);
        }
      });
    } else {
      navigate(`/login`);
    }
  };

  return Shows ? (
    <div className="features">
      <Slider className="featureSection" {...settings2}>
        {Shows.map((item, index) => (
          <div className="items" key={index}>
            <div className="background">
            <img src={item.logo_thumb} alt="Thumbnail" style={{background:'black',opacity:'0.55'}} />
            </div>
            <div className="containers">
              <div className="leftContainer">
                <div className="imageContainer">
                  <img src={item.logo_thumb} alt="Thumbnail" />
                </div>
              </div>
              <div className="rightContainer">
                <h1 className="title">{item.show_name}</h1>
                <p>
                {item.synopsis}
                </p>
                <div className="buttonContainer">
                  <button
                    onClick={() => {
                      navigate({
                        pathname: "/show",
                        search: encodeURI(`show_id=${item.show_id}`),
                      });
                    }}
                  >
                    Play
                    <img
                      src={require("../../assets/images/play-icon.png")}
                      alt="PlayIcon"
                    />
                  </button>

                  {(item.watchlist_flag === 1)? (
                    <button
                      onClick={() => {
                        removeFromMyList(item?.show_id, index);
                      }}
                      className="removeList"
                    >
                      Remove from List
                      <img
                        src={require("../../assets/images/minus.svg").default}
                        alt="CrossIcon"
                      />
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        addToMyList(item?.show_id, index);
                      }}
                    >
                      Add to List
                      <img
                        src={require("../../assets/images/icon-plus.png")}
                        alt="PlusIcon"
                      />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  ) : (
    ""
  );
}

export default Featured;
