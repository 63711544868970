import { getDeviceType , getBrowserType } from "../Utils/utils";
import qs from "qs";
const axios = require("axios");
// const qs = require("querystring");

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getGeoInfo() {
  return axios
    .get("https://giz.poppo.tv/service/ipinfo")
    .then((response) => {
      if (response.data) {
        service.setCookie("country_code", response.data.countryCode, 30);
        service.setCookie("ipaddress", response.data.query, 30);
        localStorage.setItem("ipaddress", response.data.query);
        let currentLocation = {};
        currentLocation["country_name"] = response.data.country;
        currentLocation["city"] = response.data.city;
        currentLocation["latitude"] = response.data.lat;
        currentLocation["longitude"] = response.data.lon;
        currentLocation["IPv4"] = response.data.query;
        currentLocation["state"] = response.data.region;
        localStorage.setItem(
          "currentLocation",
          JSON.stringify(currentLocation)
        );
      }
      return response.data;
    })
    .catch((error) => {});
}
function authenticate() {
  let uId = 291;
  let countryCode = getCookie("country_code");
  let ipaddress = localStorage.getItem("ipaddress");
  let deviceId = localStorage.getItem("deviceId");
  let user_id = getCookie("userId");
  if (user_id) {
    uId = user_id;
  }
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      uid: uId,
      pubid: process.env.REACT_APP_PUBID,
      country_code: countryCode,
      channelid: process.env.REACT_APP_CHANNELID,
      dev_id: deviceId,
      ip: ipaddress,
      device_type: "web",
    },
  };
  return axios
    .get(process.env.REACT_APP_API_URL + "account/authenticate", customConfig)
    .then((response) => {
      if (response.data.message == "Invalid user") {
        localStorage.removeItem("userName");
        localStorage.removeItem("userId");
        localStorage.setItem("isLoggedIn", "false");
        eraseCookie("userName");
        eraseCookie("userId");
        eraseCookie("userEmail");
        eraseCookie("subscriptionId");
      } else {
        localStorage.setItem("access-token", response.data.token);
        return response.data;
      }
    })
    .catch((error) => {
      return [];
    });
}
function eraseCookie(name) {
  document.cookie = name + "=; Max-Age=-99999999;";
}

function getGuestUser() {
  let countryCode = getCookie("country_code");
  let ipaddress = getCookie("ipaddress");
  let deviceId = localStorage.getItem("deviceId");
  const customConfig = {
    headers: {
      pubid: process.env.REACT_APP_PUBID,
      channelid: process.env.REACT_APP_CHANNELID,
      country_code: countryCode,
      device_type: "web",
      dev_id: deviceId,
      ip: ipaddress,
      ua: navigator.userAgent,
    },
  };
  return axios
    .post(
      process.env.REACT_APP_API_URL + "account/register/guest",
      "",
      customConfig
    )
    .then((response) => {
      localStorage.setItem("userId", response.data.user_id);
      service.setCookie("userId", response.data.user_id);
      service.setCookie("guestUserId", response.data.user_id);
      return true;
    })
    .catch((error) => {
      return false;
    });
}
function register(values, facebookId) {
  var token = localStorage.getItem("access-token");
  let device_id = localStorage.getItem("deviceId");
  let ipaddress = localStorage.getItem("ipaddress");
  let uId = 291;
  let user_id = getCookie("userId");
  let countryCode = getCookie("country_code");
  if (user_id) {
    uId = user_id;
  }
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": token,
      uid: uId,
      pubid: process.env.REACT_APP_PUBID,
      country_code: countryCode,
      channelid: process.env.REACT_APP_CHANNELID,
      dev_id: device_id,
      ip: ipaddress,
      device_type: "web",
    },
  };
  const data = {
    dob: values.age,
    country_code: values.country,
    gender: values.gender,
    first_name: values.fullname,
    user_email: values.email,
    password: values.password,
    facebook_id: facebookId,
    referral_code: values.ReferralCode,
  };

  return axios
    .post(
      process.env.REACT_APP_API_URL + "account/register",
      qs.stringify(data),
      customConfig
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

async function login(values) {
  var token = localStorage.getItem("access-token");
  let device_id = localStorage.getItem("deviceId");
  let ipaddress = localStorage.getItem("ipaddress");
  let uId = 291;
  let user_id = getCookie("userId");
  let countryCode = getCookie("country_code");
  if (user_id) {
    uId = user_id;
  }
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": token,
      uid: uId,
      pubid: process.env.REACT_APP_PUBID,
      country_code: countryCode,
      channelid: process.env.REACT_APP_CHANNELID,
      dev_id: device_id,
      ip: ipaddress,
      device_type: "web",
    },
    // body: {
    //     'user_email': values.email,
    //     'password': values.password,
    // }
  };
  var url = new URL(process.env.REACT_APP_API_URL + "account/login"),
    params = {
      user_email: values.email,
      password: values.password,
    };
  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key])
  );
  let res = {};
  let response = await fetch(url, customConfig);
  res.status = response.status;
  res.data = await response.json();
  return res;
}

function contact(values) {
  var token = localStorage.getItem("access-token");
  let countryCode = getCookie("country_code");

  const formData = new FormData();
  formData.append("file", values.file);

  formData.append("email", values.email);

  formData.append("topic", values.topic);

  formData.append("message", values.message);

  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": token,
      pubid: process.env.REACT_APP_PUBID,
      channelid: process.env.REACT_APP_CHANNELID,

      uid:291
    },
  };
  
  return axios
    .post(
      process.env.REACT_APP_API_URL + "user/support/send",
      formData,
      customConfig
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}
function analytics() {
  let countryCode = getCookie("country_code");
  let sessionId = localStorage.getItem("session_id");
  // setCookie('device_analytics',true);
  let uId = 291;
  let user_id = getCookie("userId");
  let email_id = getCookie("userEmail");
  if (user_id) {
    uId = user_id;
  }
  let device_id = localStorage.getItem("deviceId");
  let token = localStorage.getItem("access-token");
  let firstName = localStorage.getItem("userName");
  if (!firstName) {
    firstName = "";
  }
  let deviceType = getDeviceType();
  let ctimestamp = Date.now().toString();
  let ctime = ctimestamp.slice(0, 10);
  let browserType = getBrowserType();
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": token,
    },
  };
  let currentLocation = JSON.parse(localStorage.getItem("currentLocation"));
  const data = {
    session_id: sessionId,
    device_id: device_id,
    publisherid: process.env.REACT_APP_PUBID,
    app_id: 195,
    channelid: process.env.REACT_APP_CHANNELID,
    user_id: uId,
    ip_address: currentLocation.IPv4,
    ua: navigator.userAgent,
    timestamp: ctime,
    country: currentLocation.country_name,
    device_type: deviceType,
    city: currentLocation.city,
    latitude: currentLocation.latitude,
    longitude: currentLocation.longitude,
    width: window.innerWidth,
    height: window.innerHeight,
    device_make: navigator.userAgent,
    device_model: navigator.userAgent,
    browser: browserType,
    user_name: firstName,
    user_email: email_id,
    user_contact_number: "",
  };
  return axios
    .post("https://analytics.poppo.tv/device", qs.stringify(data), customConfig)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}
function forgotEmail(values) {
  var token = localStorage.getItem("access-token");
  let device_id = localStorage.getItem("deviceId");
  let ipaddress = localStorage.getItem("ipaddress");
  let uId = 291;
  let user_id = getCookie("userId");
  let countryCode = getCookie("country_code");
  if (user_id) {
    uId = user_id;
  }
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": token,
      uid: uId,
      pubid: process.env.REACT_APP_PUBID,
      country_code: countryCode,
      channelid: process.env.REACT_APP_CHANNELID,
      dev_id: device_id,
      ip: ipaddress,
      device_type: "web",
    },
    params: {
      user_email: values.email,
    },
  };
  return axios
    .get(process.env.REACT_APP_API_URL + "account/passwordReset", customConfig)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}
function applaunchEvent(event) {
  let sessionId = localStorage.getItem("session_id");
  let uId = service.getCookie("guestUserId");
  let user_id = getCookie("userId");
  if (user_id) {
    uId = user_id;
  }
  let device_id = localStorage.getItem("deviceId");
  let ctimestamp = Date.now().toString();
  let ctime = ctimestamp.slice(0, 10);
  let appid = "140";
  const requestBody = {
    app_id: appid,
    timestamp: ctime,
    device_id: device_id,
    event_type: event,
    publisherid: process.env.REACT_APP_PUBID,
    user_id: uId,
    channel_id: process.env.REACT_APP_CHANNELID,
    session_id: sessionId,
  };
  var token = localStorage.getItem("access-token");
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": token,
    },
  };
  return axios
    .post(
      "https://analytics.poppo.tv/event",
      qs.stringify(requestBody),
      customConfig
    )
    .then((response) => {
      sessionStorage.setItem("applaunch", true);
      return response.data;
    })
    .catch((error) => {
      sessionStorage.removeItem("applaunch");
      return [];
    });
}

export const service = {
  getCookie,
  setCookie,
  getGeoInfo,
  eraseCookie,
  authenticate,
  getGuestUser,
  register,
  login,
  contact,
  analytics,
  forgotEmail,
  applaunchEvent
};
