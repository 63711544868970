import React, { useEffect, useState } from "react";
import { service } from "../../../Network/Home/Service";
import "./accountSettings.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import defaultPlaceHolder from "../../../assets/images/blank display picture.png";
import { useDispatch, useSelector } from "react-redux";
import { getAccountDetails } from "../../../Redux/User/AccountSettingsSlice";

function AccountSettings() {
  let response = useSelector((state) => state?.AccountDetails?.value);
  const dispatch = useDispatch();

  const [PasswordFlag, setPasswordFlag] = useState(false);
  const navigate = useNavigate();
  const [profileImage, setprofileImage] = useState(``);
  const [errors, seterrors] = useState({
    fullname: "",
    email: "",
    age: "",
    gender: "",
    country: "",
    password: "",
    new_password: "",
    confirm_password: "",
  });
  const [passwordData, setpasswordData] = useState({
    password: "",
    new_password: "",
    confirm_password: "",
  });
  const [UserData, setUserData] = useState({
    fullname: "",
    email: "",
    age: "",
    gender: "",
    country: "",
  });

  const [CountryCode, setCountryCode] = useState("");

  const countryList = {
    AF: "Afghanistan",
    AL: "Albania",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarctica",
    AG: "Antigua and Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaijan",
    BS: "Bahamas (the)",
    BH: "Bahrain",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Belarus",
    BE: "Belgium",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia (Plurinational State of)",
    BQ: "Bonaire, Sint Eustatius and Saba",
    BA: "Bosnia and Herzegovina",
    BW: "Botswana",
    BV: "Bouvet Island",
    BR: "Brazil",
    IO: "British Indian Ocean Territory (the)",
    BN: "Brunei Darussalam",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    CV: "Cabo Verde",
    KH: "Cambodia",
    CM: "Cameroon",
    CA: "Canada",
    KY: "Cayman Islands (the)",
    CF: "Central African Republic (the)",
    TD: "Chad",
    CL: "Chile",
    CN: "China",
    CX: "Christmas Island",
    CC: "Cocos (Keeling) Islands (the)",
    CO: "Colombia",
    KM: "Comoros (the)",
    CD: "Congo (the Democratic Republic of the)",
    CG: "Congo (the)",
    CK: "Cook Islands (the)",
    CR: "Costa Rica",
    HR: "Croatia",
    CU: "Cuba",
    CW: "Curaçao",
    CY: "Cyprus",
    CZ: "Czechia",
    CI: "Côte d'Ivoire",
    DK: "Denmark",
    DJ: "Djibouti",
    DM: "Dominica",
    DO: "Dominican Republic (the)",
    EC: "Ecuador",
    EG: "Egypt",
    SV: "El Salvador",
    GQ: "Equatorial Guinea",
    ER: "Eritrea",
    EE: "Estonia",
    SZ: "Eswatini",
    ET: "Ethiopia",
    FK: "Falkland Islands (the) [Malvinas]",
    FO: "Faroe Islands (the)",
    FJ: "Fiji",
    FI: "Finland",
    FR: "France",
    GF: "French Guiana",
    PF: "French Polynesia",
    TF: "French Southern Territories (the)",
    GA: "Gabon",
    GM: "Gambia (the)",
    GE: "Georgia",
    DE: "Germany",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GL: "Greenland",
    GD: "Grenada",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HT: "Haiti",
    HM: "Heard Island and McDonald Islands",
    VA: "Holy See (the)",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungary",
    IS: "Iceland",
    IN: "India",
    ID: "Indonesia",
    IR: "Iran (Islamic Republic of)",
    IQ: "Iraq",
    IE: "Ireland",
    IM: "Isle of Man",
    IL: "Israel",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    KP: "Korea (the Democratic People's Republic of)",
    KR: "Korea (the Republic of)",
    KW: "Kuwait",
    KG: "Kyrgyzstan",
    LA: "Lao People's Democratic Republic (the)",
    LV: "Latvia",
    LB: "Lebanon",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MO: "Macao",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MH: "Marshall Islands (the)",
    MQ: "Martinique",
    MR: "Mauritania",
    MU: "Mauritius",
    YT: "Mayotte",
    MX: "Mexico",
    FM: "Micronesia (Federated States of)",
    MD: "Moldova (the Republic of)",
    MC: "Monaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MA: "Morocco",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Netherlands (the)",
    NC: "New Caledonia",
    NZ: "New Zealand",
    NI: "Nicaragua",
    NE: "Niger (the)",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Norfolk Island",
    MP: "Northern Mariana Islands (the)",
    NO: "Norway",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palestine, State of",
    PA: "Panama",
    PG: "Papua New Guinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippines (the)",
    PN: "Pitcairn",
    PL: "Poland",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Qatar",
    MK: "Republic of North Macedonia",
    RO: "Romania",
    RU: "Russian Federation (the)",
    RW: "Rwanda",
    RE: "Réunion",
    BL: "Saint Barthélemy",
    SH: "Saint Helena, Ascension and Tristan da Cunha",
    KN: "Saint Kitts and Nevis",
    LC: "Saint Lucia",
    MF: "Saint Martin (French part)",
    PM: "Saint Pierre and Miquelon",
    VC: "Saint Vincent and the Grenadines",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tome and Principe",
    SA: "Saudi Arabia",
    SN: "Senegal",
    RS: "Serbia",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SX: "Sint Maarten (Dutch part)",
    SK: "Slovakia",
    SI: "Slovenia",
    SB: "Solomon Islands",
    SO: "Somalia",
    ZA: "South Africa",
    GS: "South Georgia and the South Sandwich Islands",
    SS: "South Sudan",
    ES: "Spain",
    LK: "Sri Lanka",
    SD: "Sudan (the)",
    SR: "Suriname",
    SJ: "Svalbard and Jan Mayen",
    SE: "Sweden",
    CH: "Switzerland",
    SY: "Syrian Arab Republic",
    TW: "Taiwan",
    TJ: "Tajikistan",
    TZ: "Tanzania, United Republic of",
    TH: "Thailand",
    TL: "Timor-Leste",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad and Tobago",
    TN: "Tunisia",
    TR: "Turkey",
    TM: "Turkmenistan",
    TC: "Turks and Caicos Islands (the)",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukraine",
    AE: "United Arab Emirates (the)",
    GB: "United Kingdom of Great Britain and Northern Ireland (the)",
    UM: "United States Minor Outlying Islands (the)",
    US: "United States of America (the)",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VU: "Vanuatu",
    VE: "Venezuela (Bolivarian Republic of)",
    VN: "Viet Nam",
    VG: "Virgin Islands (British)",
    VI: "Virgin Islands (U.S.)",
    WF: "Wallis and Futuna",
    EH: "Western Sahara",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe",
    AX: "Åland Islands",
  };

  useEffect(() => {
    // service.getAccountDetails().then((response) => {
    if (response) {
      const c = response?.data?.country_code;
      setCountryCode(response?.data?.country_code);
      setUserData({
        fullname: response?.data?.first_name,
        email: response?.data?.user_email,
        age: response?.data?.dob.split("T")[0],
        gender: response?.data?.gender,
        country: response?.data?.country_code,
        country_string: countryList[c],
        password: "",
        new_password: "",
        confirm_password: "",
        file: response?.data?.user_image,
      });
      setprofileImage(response?.data?.user_image);
    }

    // });
  }, [response]);

  const handleFileChange = (e) => {
    const name = e?.target?.files[0]?.name;
    const files = e?.target?.files;
    setUserData({
      ...UserData,
      file: files[0],
    });
    setprofileImage(URL.createObjectURL(files[0]));
    // setSelectedImage(e.target.files[0])
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (UserData.fullname.length > 0) {
      service.updateDetails(UserData).then((response) => {
        if (response) {
          toast.success("Changes Applied!", { position: "bottom-left" });

          service.getAccountDetails().then((response) => {
            dispatch(
              getAccountDetails({
                AccountDetails: response
              })
            );
          });
          setTimeout(() => {
            navigate("/");
            
          }, 3000);
        } else {
          toast.error("Something Went Wrong!", { position: "bottom-left" });
        }
      });
    } else {
      toast.error(`Name field is required`, { position: "bottom-left" });
    }
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...UserData,
      [name]: value,
    });
  };

  const passwordChangeHandler = (e) => {
    const { name, value } = e.target;
    setpasswordData({
      ...passwordData,
      [name]: value,
    });
  };

  const passwordSubmitHandler = (e) => {
    e.preventDefault();
    if (passwordData.new_password === passwordData.confirm_password) {
      if (
        passwordData.password.length > 5 &&
        passwordData.new_password.length > 5 &&
        passwordData.confirm_password.length > 5
      ) {
        service.updateDetails(passwordData).then((response) => {
          if (response.length > 0 || response.success === true) {
            seterrors({
              ...errors,
              password: "",
              new_password: "",
              confirm_password: "",
            });
            toast.success("Password changed", { position: "bottom-left" });
            setTimeout(() => {
              navigate(`/`);
            }, 3000);
          } else {
            seterrors({
              ...errors,
              password: "Incorrect Password",
            });
          }
        });
      } else {
        seterrors({
          ...errors,
          password: "Minimum 6 Characters Needed",
          new_password: "Minimum 6 Characters Needed",
          confirm_password: "Minimum 6 Characters Needed",
        });
      }
    } else {
      seterrors({
        ...errors,
        confirm_password: `Not Matching With New Password`,
      });
    }
  };

  const replaceImage = (e) => {
    //replacement of broken Image
    e.target.src = defaultPlaceHolder;
  };

  return (
    <>
      <div id="accountSettings">
        <ToastContainer />
        <h1 className="heading">My Account Settings</h1>
        {!PasswordFlag ? (
          <div className="accountSettingsContainer">
            <div className="wrapper">
              <div className="leftContainer">
                <div className="imageContainer">
                  <img
                    src={
                      profileImage
                        ? profileImage
                        : require("../../../assets/images/blank display picture.png")
                    }
                    onError={(e) => replaceImage(e)}
                    alt="profile Image"
                  />
                </div>
                <span>
                  <input
                    style={{ display: "none" }}
                    name="file"
                    type="file"
                    id="file"
                    onChange={(e) => handleFileChange(e)}
                  />
                  <label style={{ cursor: "pointer" }} htmlFor="file">
                    Change Display Picture
                  </label>
                </span>
              </div>
              {/* ( .leftContainer ) */}
              <div className="rightContainer">
                <form onSubmit={submitHandler} method="post">
                  <div className="form-group">
                    <div className="oneByOne">
                      <label htmlFor="name">Username</label>
                      <input
                        type="text"
                        name="fullname"
                        id="name"
                        value={UserData.fullname}
                        onChange={(e) => {
                          changeHandler(e);
                        }}
                      />
                    </div>
                    <div className="oneByOne">
                      <label htmlFor="age">Age</label>
                      <input
                        type="date"
                        id="age"
                        name="age"
                        placeholder={UserData.age}
                        value={UserData.age}
                        onChange={(e) => {
                          changeHandler(e);
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="oneByOne">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        value={UserData.email}
                        onChange={(e) => {
                          changeHandler(e);
                        }}
                        disabled
                      />
                    </div>
                    <div className="oneByOne">
                      <label htmlFor="gender">Gender</label>
                      <select
                        name="gender"
                        id="gender"
                        value={UserData.gender}
                        onChange={(e) => {
                          changeHandler(e);
                        }}
                      >
                        <option value="" disabled selected>
                          - Select -
                        </option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="oneByOne">
                      <label
                        className="changePassword"
                        style={{ cursor: "pointer" }}
                        onClick={() => setPasswordFlag(true)}
                      >
                        Change Password
                      </label>
                    </div>
                    <div className="oneByOne">
                      <label htmlFor="country">Country</label>
                      <select
                        id="country"
                        name="country"
                        defaultValue={CountryCode}
                        onChange={(e) => {
                          changeHandler(e);
                        }}
                      >
                        <option defaultValue={CountryCode}>
                          {UserData?.country_string}
                        </option>
                        <option value="AF">Afghanistan</option>
                        <option value="AX">Aland Islands</option>
                        <option value="AL">Albania</option>
                        <option value="DZ">Algeria</option>
                        <option value="AS">American Samoa</option>
                        <option value="AD">Andorra</option>
                        <option value="AO">Angola</option>
                        <option value="AI">Anguilla</option>
                        <option value="AQ">Antarctica</option>
                        <option value="AG">Antigua and Barbuda</option>
                        <option value="AR">Argentina</option>
                        <option value="AM">Armenia</option>
                        <option value="AW">Aruba</option>
                        <option value="AU">Australia</option>
                        <option value="AT">Austria</option>
                        <option value="AZ">Azerbaijan</option>
                        <option value="BS">Bahamas</option>
                        <option value="BH">Bahrain</option>
                        <option value="BD">Bangladesh</option>
                        <option value="BB">Barbados</option>
                        <option value="BY">Belarus</option>
                        <option value="BE">Belgium</option>
                        <option value="BZ">Belize</option>
                        <option value="BJ">Benin</option>
                        <option value="BM">Bermuda</option>
                        <option value="BT">Bhutan</option>
                        <option value="BO">Bolivia</option>
                        <option value="BQ">
                          Bonaire, Sint Eustatius and Saba
                        </option>
                        <option value="BA">Bosnia and Herzegovina</option>
                        <option value="BW">Botswana</option>
                        <option value="BV">Bouvet Island</option>
                        <option value="BR">Brazil</option>
                        <option value="IO">
                          British Indian Ocean Territory
                        </option>
                        <option value="BN">Brunei Darussalam</option>
                        <option value="BG">Bulgaria</option>
                        <option value="BF">Burkina Faso</option>
                        <option value="BI">Burundi</option>
                        <option value="KH">Cambodia</option>
                        <option value="CM">Cameroon</option>
                        <option value="CA">Canada</option>
                        <option value="CV">Cape Verde</option>
                        <option value="KY">Cayman Islands</option>
                        <option value="CF">Central African Republic</option>
                        <option value="TD">Chad</option>
                        <option value="CL">Chile</option>
                        <option value="CN">China</option>
                        <option value="CX">Christmas Island</option>
                        <option value="CC">Cocos (Keeling) Islands</option>
                        <option value="CO">Colombia</option>
                        <option value="KM">Comoros</option>
                        <option value="CG">Congo</option>
                        <option value="CD">
                          Congo, Democratic Republic of the Congo
                        </option>
                        <option value="CK">Cook Islands</option>
                        <option value="CR">Costa Rica</option>
                        <option value="CI">Cote D'Ivoire</option>
                        <option value="HR">Croatia</option>
                        <option value="CU">Cuba</option>
                        <option value="CW">Curacao</option>
                        <option value="CY">Cyprus</option>
                        <option value="CZ">Czech Republic</option>
                        <option value="DK">Denmark</option>
                        <option value="DJ">Djibouti</option>
                        <option value="DM">Dominica</option>
                        <option value="DO">Dominican Republic</option>
                        <option value="EC">Ecuador</option>
                        <option value="EG">Egypt</option>
                        <option value="SV">El Salvador</option>
                        <option value="GQ">Equatorial Guinea</option>
                        <option value="ER">Eritrea</option>
                        <option value="EE">Estonia</option>
                        <option value="ET">Ethiopia</option>
                        <option value="FK">Falkland Islands (Malvinas)</option>
                        <option value="FO">Faroe Islands</option>
                        <option value="FJ">Fiji</option>
                        <option value="FI">Finland</option>
                        <option value="FR">France</option>
                        <option value="GF">French Guiana</option>
                        <option value="PF">French Polynesia</option>
                        <option value="TF">French Southern Territories</option>
                        <option value="GA">Gabon</option>
                        <option value="GM">Gambia</option>
                        <option value="GE">Georgia</option>
                        <option value="DE">Germany</option>
                        <option value="GH">Ghana</option>
                        <option value="GI">Gibraltar</option>
                        <option value="GR">Greece</option>
                        <option value="GL">Greenland</option>
                        <option value="GD">Grenada</option>
                        <option value="GP">Guadeloupe</option>
                        <option value="GU">Guam</option>
                        <option value="GT">Guatemala</option>
                        <option value="GG">Guernsey</option>
                        <option value="GN">Guinea</option>
                        <option value="GW">Guinea-Bissau</option>
                        <option value="GY">Guyana</option>
                        <option value="HT">Haiti</option>
                        <option value="HM">
                          Heard Island and Mcdonald Islands
                        </option>
                        <option value="VA">
                          Holy See (Vatican City State)
                        </option>
                        <option value="HN">Honduras</option>
                        <option value="HK">Hong Kong</option>
                        <option value="HU">Hungary</option>
                        <option value="IS">Iceland</option>
                        <option value="IN">India</option>
                        <option value="ID">Indonesia</option>
                        <option value="IR">Iran, Islamic Republic of</option>
                        <option value="IQ">Iraq</option>
                        <option value="IE">Ireland</option>
                        <option value="IM">Isle of Man</option>
                        <option value="IL">Israel</option>
                        <option value="IT">Italy</option>
                        <option value="JM">Jamaica</option>
                        <option value="JP">Japan</option>
                        <option value="JE">Jersey</option>
                        <option value="JO">Jordan</option>
                        <option value="KZ">Kazakhstan</option>
                        <option value="KE">Kenya</option>
                        <option value="KI">Kiribati</option>
                        <option value="KP">
                          Korea, Democratic People's Republic of
                        </option>
                        <option value="KR">Korea, Republic of</option>
                        <option value="XK">Kosovo</option>
                        <option value="KW">Kuwait</option>
                        <option value="KG">Kyrgyzstan</option>
                        <option value="LA">
                          Lao People's Democratic Republic
                        </option>
                        <option value="LV">Latvia</option>
                        <option value="LB">Lebanon</option>
                        <option value="LS">Lesotho</option>
                        <option value="LR">Liberia</option>
                        <option value="LY">Libyan Arab Jamahiriya</option>
                        <option value="LI">Liechtenstein</option>
                        <option value="LT">Lithuania</option>
                        <option value="LU">Luxembourg</option>
                        <option value="MO">Macao</option>
                        <option value="MK">
                          Macedonia, the Former Yugoslav Republic of
                        </option>
                        <option value="MG">Madagascar</option>
                        <option value="MW">Malawi</option>
                        <option value="MY">Malaysia</option>
                        <option value="MV">Maldives</option>
                        <option value="ML">Mali</option>
                        <option value="MT">Malta</option>
                        <option value="MH">Marshall Islands</option>
                        <option value="MQ">Martinique</option>
                        <option value="MR">Mauritania</option>
                        <option value="MU">Mauritius</option>
                        <option value="YT">Mayotte</option>
                        <option value="MX">Mexico</option>
                        <option value="FM">
                          Micronesia, Federated States of
                        </option>
                        <option value="MD">Moldova, Republic of</option>
                        <option value="MC">Monaco</option>
                        <option value="MN">Mongolia</option>
                        <option value="ME">Montenegro</option>
                        <option value="MS">Montserrat</option>
                        <option value="MA">Morocco</option>
                        <option value="MZ">Mozambique</option>
                        <option value="MM">Myanmar</option>
                        <option value="NA">Namibia</option>
                        <option value="NR">Nauru</option>
                        <option value="NP">Nepal</option>
                        <option value="NL">Netherlands</option>
                        <option value="AN">Netherlands Antilles</option>
                        <option value="NC">New Caledonia</option>
                        <option value="NZ">New Zealand</option>
                        <option value="NI">Nicaragua</option>
                        <option value="NE">Niger</option>
                        <option value="NG">Nigeria</option>
                        <option value="NU">Niue</option>
                        <option value="NF">Norfolk Island</option>
                        <option value="MP">Northern Mariana Islands</option>
                        <option value="NO">Norway</option>
                        <option value="OM">Oman</option>
                        <option value="PK">Pakistan</option>
                        <option value="PW">Palau</option>
                        <option value="PS">
                          Palestinian Territory, Occupied
                        </option>
                        <option value="PA">Panama</option>
                        <option value="PG">Papua New Guinea</option>
                        <option value="PY">Paraguay</option>
                        <option value="PE">Peru</option>
                        <option value="PH">Philippines</option>
                        <option value="PN">Pitcairn</option>
                        <option value="PL">Poland</option>
                        <option value="PT">Portugal</option>
                        <option value="PR">Puerto Rico</option>
                        <option value="QA">Qatar</option>
                        <option value="RE">Reunion</option>
                        <option value="RO">Romania</option>
                        <option value="RU">Russian Federation</option>
                        <option value="RW">Rwanda</option>
                        <option value="BL">Saint Barthelemy</option>
                        <option value="SH">Saint Helena</option>
                        <option value="KN">Saint Kitts and Nevis</option>
                        <option value="LC">Saint Lucia</option>
                        <option value="MF">Saint Martin</option>
                        <option value="PM">Saint Pierre and Miquelon</option>
                        <option value="VC">
                          Saint Vincent and the Grenadines
                        </option>
                        <option value="WS">Samoa</option>
                        <option value="SM">San Marino</option>
                        <option value="ST">Sao Tome and Principe</option>
                        <option value="SA">Saudi Arabia</option>
                        <option value="SN">Senegal</option>
                        <option value="RS">Serbia</option>
                        <option value="CS">Serbia and Montenegro</option>
                        <option value="SC">Seychelles</option>
                        <option value="SL">Sierra Leone</option>
                        <option value="SG">Singapore</option>
                        <option value="SX">Sint Maarten</option>
                        <option value="SK">Slovakia</option>
                        <option value="SI">Slovenia</option>
                        <option value="SB">Solomon Islands</option>
                        <option value="SO">Somalia</option>
                        <option value="ZA">South Africa</option>
                        <option value="GS">
                          South Georgia and the South Sandwich Islands
                        </option>
                        <option value="SS">South Sudan</option>
                        <option value="ES">Spain</option>
                        <option value="LK">Sri Lanka</option>
                        <option value="SD">Sudan</option>
                        <option value="SR">Suriname</option>
                        <option value="SJ">Svalbard and Jan Mayen</option>
                        <option value="SZ">Swaziland</option>
                        <option value="SE">Sweden</option>
                        <option value="CH">Switzerland</option>
                        <option value="SY">Syrian Arab Republic</option>
                        <option value="TW">Taiwan, Province of China</option>
                        <option value="TJ">Tajikistan</option>
                        <option value="TZ">Tanzania, United Republic of</option>
                        <option value="TH">Thailand</option>
                        <option value="TL">Timor-Leste</option>
                        <option value="TG">Togo</option>
                        <option value="TK">Tokelau</option>
                        <option value="TO">Tonga</option>
                        <option value="TT">Trinidad and Tobago</option>
                        <option value="TN">Tunisia</option>
                        <option value="TR">Turkey</option>
                        <option value="TM">Turkmenistan</option>
                        <option value="TC">Turks and Caicos Islands</option>
                        <option value="TV">Tuvalu</option>
                        <option value="UG">Uganda</option>
                        <option value="UA">Ukraine</option>
                        <option value="AE">United Arab Emirates</option>
                        <option value="GB">United Kingdom</option>
                        <option value="US">United States</option>
                        <option value="UM">
                          United States Minor Outlying Islands
                        </option>
                        <option value="UY">Uruguay</option>
                        <option value="UZ">Uzbekistan</option>
                        <option value="VU">Vanuatu</option>
                        <option value="VE">Venezuela</option>
                        <option value="VN">Viet Nam</option>
                        <option value="VG">Virgin Islands, British</option>
                        <option value="VI">Virgin Islands, U.s.</option>
                        <option value="WF">Wallis and Futuna</option>
                        <option value="EH">Western Sahara</option>
                        <option value="YE">Yemen</option>
                        <option value="ZM">Zambia</option>
                        <option value="ZW">Zimbabwe</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="oneByOne">
                      <button type="submit">Save changes</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>{" "}
          </div>
        ) : (
          <div className="accountSettingsContainer">
            <div className="wrapper">
              <div className="leftContainer">
                <div className="imageContainer">
                  <img
                    src={
                      profileImage
                        ? profileImage
                        : require("../../../assets/images/blank display picture.png")
                    }
                    alt="Logo"
                    onError={(e) => replaceImage(e)}
                    style={{
                      borderRadius: " 1000px",
                      height: "200px",
                      width: "200px",
                    }}
                  />
                </div>
                {/* <span>Change Display Picture</span> */}
              </div>
              {/* ( .leftContainer ) */}
              <div className="rightContainer">
                <form onSubmit={passwordSubmitHandler} method="post">
                  <div className="form-group">
                    <div className="oneByOne">
                      {errors?.password?.length > 0 && (
                        <div style={{ color: "red" }}>{errors?.password}</div>
                      )}

                      <label htmlFor="password">Current Password</label>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        value={passwordData.password}
                        onChange={(e) => {
                          passwordChangeHandler(e);
                        }}
                      />
                    </div>
                    <div className="oneByOne">
                      {errors?.new_password?.length > 0 && (
                        <div style={{ color: "red" }}>
                          {errors?.new_password}
                        </div>
                      )}
                      <label htmlFor="password">New Password</label>
                      <input
                        type="password"
                        id="new_password"
                        name="new_password"
                        value={passwordData.new_password}
                        onChange={(e) => {
                          passwordChangeHandler(e);
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="oneByOne">
                      {errors?.confirm_password?.length > 0 && (
                        <div style={{ color: "red" }}>
                          {errors?.confirm_password}
                        </div>
                      )}

                      <label htmlFor="password">Confirm New Password</label>
                      <input
                        type="password"
                        name="confirm_password"
                        id="confirm_password"
                        value={passwordData?.confirm_password}
                        onChange={(e) => {
                          passwordChangeHandler(e);
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="oneByOne">
                      <button type="submit">Reset Password</button>
                    </div>
                    <div className="oneByOne">
                      <button
                        type="button"
                        onClick={() => setPasswordFlag(false)}
                      >
                        Edit Profile
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              {/* ( .rightContainer ) */}
            </div>{" "}
            {/* ( .wrapper ) */}
          </div>
        )}
      </div>
    </>
  );
}

export default AccountSettings;
