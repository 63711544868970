import React, { useEffect, useState } from "react";
import "./changePassword.css";
import { service } from "../../../Network/Home/Service";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ChangePassword() {
  const navigate = useNavigate();
  let isLoggedIn = localStorage.getItem("isLoggedIn");
  const { id } = useParams();
  const [passwordValues, setPasswordValues] = useState({
    password: "",
    confirmPassword: "",
    token: id,
  });
  const [Errors, setErrors] = useState({
    password: "",
    confirmPassword: "",
  });
  useEffect(() => {
    if (isLoggedIn) {
      logoutHandler();
    }
  }, []);

  const clearUserData = () => {
    localStorage.removeItem("userName");
    localStorage.removeItem("userId");
    localStorage.removeItem("deviceAnalytics");
    // service.setCookie("isLoggedIn", false, 30);
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("showId");
    eraseCookie("isLoggedIn");
    eraseCookie("showId");
    eraseCookie("videoId");
    eraseCookie("userName");
    eraseCookie("userId");
    eraseCookie("userEmail");
    eraseCookie("subscriptionId");
  };
  const eraseCookie = (name) => {
    document.cookie = name + "=; Max-Age=-99999999;";
  };

  const logoutHandler = () => {
    let user = localStorage.getItem("userId");
    service.logoutFunction(user).then((response) => {
      if (response.success == true) {
        clearUserData();
        window.location.href = "/";
      } else {
        //console.log("somthing went wrong!");
      }
    });
  };
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setPasswordValues({
      ...passwordValues,
      [name]: value,
    });
  };

  const validate = (object) => {
    if (!(object.password.length > 5)) {
      toast.error("Minimum Password Length is 6", { position: "top-center" });
      return false;
    } else {
      if (!(object.password === object.confirmPassword)) {
        toast.error("Passwords are not matching", { position: "top-center" });
        return false;
      } else {
        return true;
      }
    }
  };
  const submitHandler = (e) => {
    e.preventDefault();
    if (validate(passwordValues)) {
      service.forgotPassword(passwordValues).then((response) => {
        if (response.success === true) {
          toast.success(`Password changed successfully`, {
            position: "top-center",
          });
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        }
        else {
          toast.error(response.message, {
            position: "top-center",
          });
        }
      }).catch((error) => {
        toast.error(`Something went wrong, please try again`, {
          position: "top-center",
        })
      });
    } else {
      console.log(`submit return false`);
    }
  };
  return (
    <div id="changePassword">
      <ToastContainer />
      <h1 className="heading">Change Password</h1>
      <div className="passwordContainer">
        <div className="wrapper">
          <div className="leftContainer">
            <div className="imageContainer">
              <img
                src={require("../../../assets/images/kicctv logo.png")}
                alt="Logo"
              />
            </div>
          </div>{" "}
          {/* ( .leftContainer ) */}
          <div className="rightContainer">
            <form onSubmit={submitHandler} method="post">
              <div className="form-group">
                <div className="oneByOne">
                  <label htmlFor="new-password">New Password</label>
                  <input
                    type="password"
                    value={passwordValues.password}
                    onChange={changeHandler}
                    name="password"
                    id="new-password"
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="oneByOne">
                  <label htmlFor="confirm-password">Confirm Password</label>
                  <input
                    type="password"
                    value={passwordValues.confirmPassword}
                    onChange={changeHandler}
                    name="confirmPassword"
                    id="confirm-password"
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="oneByOne">
                  <button type="submit">Update Password</button>
                </div>
              </div>
            </form>
          </div>{" "}
          {/* ( .rightContainer ) */}
        </div>{" "}
        {/* ( .wrapper ) */}
      </div>{" "}
      {/* ( .passwordContainer ) */}
    </div>
  );
}

export default ChangePassword;
