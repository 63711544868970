import React, { useState } from "react";
import { service } from "../../Network/Network";
import { ToastContainer, toast } from "react-toastify";
import { GoogleReCaptchaProvider , GoogleReCaptcha } from "react-google-recaptcha-v3";
import "./prayerScreen.css";
import { ReCAPTCHA } from "react-google-recaptcha";

function PrayerScreen() {
  const app_id = 195;
  const [imageName, setImageName] = useState("");
  const [captchaFlag, setcaptchaFlag] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  const [Message, setMessage] = useState();
  const [values, setValues] = useState({
    topic: "Prayer",
    message: "",
    email:"",
    file: [],
  });

  const SITE_KEY = "6Ldvi7kiAAAAAHYIK71Ui00zOyab772WJmA2wOt5";
  const wordLength = (length) => {
    let wordLen = 250 - length;
    setWordCount(wordLen);
  };

  const handleFileChange = (e) => {
    const name = e?.target?.files[0]?.name;
    const files = e?.target?.files;
    setImageName(name);

    setValues({
      ...values,
      file: files[0],
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (captchaFlag) {
      service.contact(values).then((response) => {
        if (response.success) {
          toast.success("Prayer sent", { position: "bottom-left" });
          setValues({
            topic: "Prayer",
            message: "",
            file: [],
          });
        }
      });
    } else {
      toast.error("Captcha failed", { position: "bottom-left" });
    }
  };

  const captchaChange = () => {
    setcaptchaFlag(true);
  };
  return (
    <div id="prayer">
      <ToastContainer />
      <h1 className="heading">Join us in Prayer</h1>
      <div className="content">
        <div className="wrapper">
          <h3>We are available to hear your prayers.</h3>
          <p className="description">
            Write your prayer in the field below and send it to our team. You
            may attach media if you wish to send us a video message or an image
            to compliment your prayer.
          </p>
          <div className="MessageContainer">
            <form onSubmit={(e) => submitHandler(e)} method="post">
              <div className="textArea">
              <input type="email" name="Email" value={values.email} placeholder="Your Email"
              onChange={(e) => {
                setValues({
                  ...values,
                  email: e.target.value,
                });
              }}
              />
              <br /><br />

                <textarea
                value={values.message}
                placeholder="Your Prayer"
                  name="message"
                  maxLength="250"
                  id=""
                  required
                  style={{ height: "290px" }}
                  onChange={(e) => {
                    wordLength(e.target.value.length);
                    setValues({
                      ...values,
                      message: e.target.value,
                    });
                  }}
                ></textarea>
              </div>
              <div className="textAreaDetails">
                <div className="left">
                  <p className={imageName ? "imageName" : "no-active"}>
                    {imageName}
                  </p>
                  <span>Word limit : </span>
                  <span>{wordCount}/250</span>
                </div>
                <div className="right">
                  <label htmlFor="file">
                    <div className="button">
                      <img
                        src={require("../../assets/images/icon-clip.png")}
                        alt="FileIcon"
                      />
                    </div>
                  </label>
                  <input
                    type="file"
                    id="file"
                    onChange={(e) => handleFileChange(e)}
                  />
                </div>
              </div>{" "}
              {/* ( .textAreaDetails ) */}
              <div className="captcha">
                {/* <ReCAPTCHA sitekey={SITE_KEY} onChange={captchaChange} /> */}
                <GoogleReCaptchaProvider reCaptchaKey={SITE_KEY}>
                  <GoogleReCaptcha onVerify={captchaChange} />
                </GoogleReCaptchaProvider>
              </div>
              <div className="submit">
                <button type="submit">Send Prayer</button>
              </div>
            </form>
          </div>{" "}
          {/* ( .messageContainer ) */}
        </div>{" "}
        {/* ( .wrapper ) */}
      </div>
      <div className="adBannerContainer">
        <img src={require("../../assets/images/ad-banner-thin.jpg")} alt="" />
      </div>
      {/* ( .content ) */}
    </div> // ( #prayer )
  );
}

export default PrayerScreen;
