import React, { useRef } from "react";
import { convertTimeToLocal } from "../../Utils/utils";
import { useState, useEffect } from "react";
function RightContainer({ LiveData,param }) {
  const [triggered, setTriggered] = useState(false);
  const [triggerState, setTriggerState] = useState(false);
  const [targetTime, setTargetTime] = useState(param.Data.shows[0].up_next.start_time);
  
  const [LiveLink, setLiveLink] = useState("");
  const rightContainerRef = useRef(null);

  useEffect(() => {
    const scheduleRefresh = () => {
      
      if (LiveData?.shows && LiveData.shows.length > 0) {
        const currentTime = new Date();
        const nextVideoStartTime = LiveData.shows[0]?.up_next?.start_time;
        const targetVideoTime = new Date(nextVideoStartTime);
        let timeUntilTarget = targetVideoTime - currentTime;
        timeUntilTarget = timeUntilTarget + 5000;

        const timeUntilNextVideoInSeconds = timeUntilTarget / 1000;


        if (timeUntilNextVideoInSeconds > 0 && !triggered) {
          const timeoutId = setTimeout(() => {
            setTriggerState(true);
            setTriggered(true);
            param.setLiveChanged(!param.liveChanged);

            rightContainerRef.current && rightContainerRef.current.forceUpdate();

          }, timeUntilTarget);

          return () => {
            clearTimeout(timeoutId);
          };
        }
      }
    };
    scheduleRefresh();
    return () => {
      setTriggered(false);
    };
  }, [LiveData, targetTime, triggered]);


  useEffect(() => {
    videoSchdeuleChange();
  }, [triggerState]);


  const videoSchdeuleChange = () => {

    // setLiveData(param && param.Data);
    setLiveLink(param.LiveLink);
    setTargetTime(param.Data.shows[0].up_next.start_time);
  }

    return (
      <div className="contents">
        <div className="top">
          <button className="nowPlaying">Now Playing</button>
          <h1>
            {LiveData.shows[0]?.now_playing?.video_title
              ? LiveData.shows[0]?.now_playing?.video_title?.slice(0, 40)
              : LiveData.shows[0]?.channel_name}
            {LiveData.shows[0]?.now_playing?.video_title?.length > 40 &&
              "..."}
          </h1>
          <span>
            {convertTimeToLocal(
              LiveData.shows[0]?.now_playing?.start_time
            )}
          </span>
          <p>
            {LiveData.shows[0]?.now_playing?.video_description &&
              LiveData.shows[0]?.now_playing?.video_description}
            {
              // LiveData?.shows[0]?.now_playing?.video_description?.length>80&&"..."
            }
          </p>
        </div>
        <div className="bottom">
          <button className="upNext">Up Next</button>
          <h1>
            {LiveData.shows[0]?.up_next?.video_title
              ? LiveData.shows[0]?.up_next?.video_title
              : LiveData.shows[0]?.channel_name}
          </h1>
          <span>
            {convertTimeToLocal(LiveData.shows[0]?.up_next?.start_time)}
          </span>
          <p>
            {LiveData.shows[0]?.up_next?.video_description &&
              LiveData.shows[0]?.up_next?.video_description}
            {LiveData.shows[0]?.up_next?.video_description?.length >
              59 && "..."}
          </p>
        </div>
      </div>
    );
  }
  export default RightContainer;