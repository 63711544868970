import React from 'react'
import Header from '../../components/Header/Header'

function DonateScreen() {
  return (
    <div>
    </div>
  )
}

export default DonateScreen