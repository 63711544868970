import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { service } from "../../Network/Network";
import Slider from "react-slick";

function BannerSection(props) {
  const [TopBanner, setTopBanner] = useState([]);
  let isLoggedIn = localStorage.getItem("isLoggedIn");
  let userId = service.getCookie("userId");



  var settings2 = {
    dots: false,
    infinite: true,
    loop: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  useEffect(() => {
    props && setTopBanner(props.Data.shows);
  }, [props]);

  return TopBanner ? (
    <div className="bannerSection">
      <Slider className="imageContainer" {...settings2}>
        {
          TopBanner.map((item, index) => {
            return (
              <div className="imageContainer" key={index}>
                {isLoggedIn && userId ? (
                  <>
                    {

                      item?.redirect_url !== '' && item?.redirect_url !== null ? (
                        <Link to={item.redirect_url}>
                          <img src={item.banner} alt="Banner-Image" />
                        </Link>
                      ) :
                        <Link to="/">
                          <img src={item?.banner} alt="Banner-Image" />
                        </Link>
                    }
                  </>
                ) : (
                  item?.redirect_url !== '' && item?.redirect_url !== null  ? (
                    <>
                    {
                      item?.redirect_url == '/how-to-watch' ?(
                        <Link to={item.redirect_url}>
                        <img src={item.banner} alt="Banner-Image" />
                        </Link>)
                    :(
                      <Link to="/register">
                        <img src={item.banner} alt="Banner-Image" />
                      </Link>)
                    }
                    </>
                    )
  
                :(
                  <Link to="/">
                    <img src={item?.banner} alt="Banner-Image" />
                  </Link>)
                )}

              </div>
            )
          })
        }

      </Slider>
    </div>
  ) : (
    ""
  );
}

export default BannerSection;
