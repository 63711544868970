import React, { useEffect, useState } from "react";
import { service } from "../../Network/Home/Service";
import "./catchUpScreen.css";
import VideoJS from "../../components/Videojs/VideoJS";
import { convertTimeToLocal } from "../../Utils/utils";
import defaultPlaceHolder from "../../assets/images/PlaceHolderShows.jpg";
import { ReactComponent as Closeicon } from "../../assets/images/close.svg";
import useScrollPosition from "../../components/ScrollPosition/useScrollPosition";

function CatchUpScreen() {
  const [playVideo, setPlayVideo] = useState(false);
  const [todayShows, setTodayShows] = useState([]);
  const [YesterdayShows, setYesterdayShows] = useState([]);
  const [TheDayBeforeShows, setTheDayBeforeShows] = useState([]);
  const [showList, setShowList] = useState([]);
  const [newUrl, setNewUrl] = useState("");
  const [videoPlayer, setVideoPlayer] = useState();
  const [buttonActive, setButtonActive] = useState("today");
  const [FlagChange, setFlagChange] = useState(false);
  const [downArrow, setDownArrow] = useState(true);
  const [filterCount, setFilterCount] = useState(10);
  const [pipMode, setPipMode] = useState(false);
  const [browserPipMode, setBrowserPipMode] = useState(false);
  const position = useScrollPosition();
  const [todayDate, setTodayDate] = useState("");
  const [yesterdayDate, setYesterdayDate] = useState("");
  const [dayBeforeDate, setDayBeforeDate] = useState("");
  const [day,setDay]= useState("");
  useEffect(() => {
    if (position === 0) {
      setPipMode(true);
    }
  }, [position]);

  useEffect(() => {
    if (filterCount >= showList.length) {
      setDownArrow(false);
    } else {
      setDownArrow(true);
    }
  }, [filterCount, showList]);

  useEffect(() => {
    window.scrollTo(0, 0);
    service.catchUpShows().then((response) => {
      response.data.map((item, index) => {
        if (item.key === "Today") {
          setTodayShows(item.data);
          setShowList(item.data);
          playHandler(item?.data[0]?.url);
          setTodayDate(item.value); 
        } else if (item.key === "Yesterday") {
          setYesterdayShows(item.data);
          setYesterdayDate(item.value);
        } else if (item.key === "The Day Before") {
          setTheDayBeforeShows(item.data);
          setDayBeforeDate(item.value);
        }
      });
    });
  }, []);

  const loadMore = () => {
    setFilterCount(filterCount + 7);
  };

  const playHandler = (link) => {
    setVideoPlayer(null);
    setNewUrl(link);
    setFlagChange(!FlagChange);
  };

  useEffect(() => {
    // if (newUrl) {
    const VideoJsOptions = {
      autoplay: true,
      controls: true,
      responsive: true,
      fluid: true,
      controlBar: {
        pictureInPictureToggle: false,
      },
      sources: [
        {
          src: `${newUrl}`,
          type: "application/x-mpegURL",
        },
      ],
    };
    setVideoPlayer(
      <div>
        {" "}
        <VideoJS options={VideoJsOptions} />
      </div>
    );

    // }
    window.scrollTo(0, 0);
  }, [FlagChange]);

  window.addEventListener("enterpictureinpicture", () => {
    setBrowserPipMode(true);
  });

  window.addEventListener("leavepictureinpicture", () => {
    setBrowserPipMode(false);
  });

  const replaceImage = (e) => {
    //replacement of broken Image
    e.target.src = defaultPlaceHolder;
  };


  return todayShows || YesterdayShows || TheDayBeforeShows ? (
    <div id="catchUp">
      <div id="spotlight">
        <div
          className={!playVideo ? "thumbnailContainer" : "no-active"}
          onClick={() => {
            setPlayVideo(true);
          }}
        >
          <img
            className="bg"
            src={require("../../assets/images/live-page-bg.png")}
            alt="Background"
          />
          <img
            className="play-icon"
            src={require("../../assets/images/play.svg").default}
            alt="Play"
          />
        </div>
        {
            position>700&& pipMode === true && playVideo ===true && browserPipMode === false &&

          <div
          className="thumbnailContainer"
        >
          <img
            className="bg"
            src={require("../../assets/images/live-page-bg.png")}
            alt="Background"
          />
        </div>
        }
        {playVideo && (
          <div className={playVideo ? "videoContainer" : "no-active"}>
            <div
              className={
                pipMode === true &&
                position > 700 &&
                browserPipMode === false &&
                playVideo === true
                  ? "Vplayer pipMode"
                  : "Vplayer"
              }
            >
              <div className="closeIcon" onClick={() => setPipMode(false)}>
                <Closeicon />
              </div>
              {videoPlayer}
            </div>
          </div>
        )}
        <div className="daysButtonContainer">
          <div className="wrapper">
            <button
              className={buttonActive == "today" ? "activeButton" : "today"}
              onClick={() => {
                setFilterCount(10);
                setShowList(todayShows);
                setButtonActive("today");
                setDay(todayDate);
              }}
            >
             {todayDate}
            </button>
            <button
              className={
                buttonActive == "yesterday" ? "activeButton" : "yesterday"
              }
              onClick={() => {
                setFilterCount(10);
                setShowList(YesterdayShows);
                setButtonActive("yesterday");
                setDay(yesterdayDate);
              }}
            >
              {yesterdayDate}
            </button>
            <button
              className={
                buttonActive == "day before" ? "activeButton" : "day-before"
              }
              onClick={() => {
                setFilterCount(10);
                setShowList(TheDayBeforeShows);
                setButtonActive("day before");
                setDay(dayBeforeDate);
              }}
            >
             {dayBeforeDate}
            </button>
          </div>
        </div>
      </div>{" "}
      {/* // ( #spotlight ) */}
      <div id="allShows">
        <div className="wrapper">
          <div className="showsContainer">
            {showList.length > 0
              ? showList
                  ?.filter((item, index) => index < filterCount)
                  ?.map((item, index) => (
                    <div className="shows" key={index}>
                      <h3>
                        {" "}
                        {item.starttime &&
                          convertTimeToLocal(item.starttime)}{" "}
                      </h3>
                      <div className="showContainer">
                        <div className="leftContainer">
                          <div className="imageContainer">
                            <img
                              src={item.thumbnail_350_200}
                              alt="Thumbnail"
                              onError={(e) => replaceImage(e)}
                            />
                          </div>
                        </div>
                        <div className="rightContainer">
                          <h1 className="title"> {item.video_title} </h1>
                          <p className="description">
                            {item.video_description}
                          </p>
                          <div className="buttonContainer">
                            <button
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                // setVideoPlayer(null);
                                playHandler(item.url);
                                setPlayVideo(true);
                              }}
                            >
                              Play Episode
                              <img
                                src={require("../../assets/images/play-icon.png")}
                                alt=""
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
              : `No Shows To Catch Up`}
          </div>{" "}
          {/* // ( showContainer ) */}
        </div>{" "}
        {/* // ( wrapper ) */}
        <div className="bottom" style={{ display: !downArrow && "none" }}>
          {downArrow && (
            <div className="downArrowContainer" onClick={loadMore}>
              <img
                src={require("../../assets/images/down-arrow-flat.png")}
                alt="Down-arrow"
              />
            </div>
          )}
        </div>
      </div>{" "}
      {/* // ( allshows ) */}
    </div> // ( #catchup )
  ) : (
    console.log(`error`)
  );
}

export default CatchUpScreen;
