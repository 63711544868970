import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import VideoJS from "../../components/Videojs/VideoJS";
import { service } from "../../Network/Home/Service";
import { convertTimeToLocal } from "../../Utils/utils";
import { ReactComponent as Closeicon } from "../../assets/images/close.svg";
import useScrollPosition from "../../components/ScrollPosition/useScrollPosition";
import RightContainer from "./RightContainer";

let debounce = true;
var videoStarted = false;
let seconds = 60;
let prevTime;
let videoPlaying = false;
function LiveSection(param) {

  
  const [pipMode, setPipMode] = useState(false);
  const [browserPipMode, setBrowserPipMode] = useState(false);
  const [playerData, setPlayerData] = useState([]);
  const position = useScrollPosition();
  var details = [];
  const playerRef = React.useRef(null);

  const [triggerState, setTriggerState] = useState(false);
  const [targetTime, setTargetTime] = useState(param.Data.shows[0].up_next.start_time);
  const [triggered, setTriggered] = useState(false);
  const rightContainerRef = useRef(null);

  useEffect(() => {
    if (position === 0) {
      setPipMode(true);
    }
  }, [position]);

  useEffect(() => {
    setPlayerData(param && param.Data);
   
  }, []);



  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    controlBar: {
      pictureInPictureToggle: false,
    },
    fluid: true,
    poster: require("../../assets/images/live-page-bg.png"),
    sources: [
      {
        src: param?.LiveLink,
        type: "application/x-mpegURL",
      },
    ],
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.on("loadedmetadata", () => { });

    player.on("play", () => {
      let event = videoStarted == true ? "POP09" : "POP02";
      videoStarted = true;
      service
        .onVideoPlayFunctn(
          playerData?.shows[0]?.now_playing,
          event,
          player.currentTime()
        )
        .then((response) => { });
    });

    player.on("timeupdate", function (e) {
      videoPlaying = true;

      let event = "POP03";

      var hasPlayedTime = player.currentTime();
      var intPlayedTime = parseInt(hasPlayedTime, 10);
      var isSixty = intPlayedTime % seconds === 0 && intPlayedTime !== 0;
      if (isSixty && debounce) {
        debounce = false;
        prevTime = intPlayedTime;
        service
          .onVideoPlayFunctn(
            playerData?.shows[0]?.now_playing,
            event,
            player.currentTime()
          )
          .then((response) => { });
      } else {
        if (debounce == false && prevTime != intPlayedTime) {
          debounce = true;
        }
      }
    });

    player.on("pause", () => {
      let event = "POP04";
      service
        .onVideoPlayFunctn(
          playerData?.shows[0]?.now_playing,
          event,
          player.currentTime()
        )
        .then((response) => { });
    });
    player.on("ended", () => {
      let event = "POP05";
      service
        .onVideoPlayFunctn(
          playerData?.shows[0]?.now_playing,
          event,
          player.currentTime()
        )
        .then((response) => { });
    });

    player.on("dispose", () => {
      videoStarted = false;
      player.pause();
    });

    //
  };
  window.addEventListener("enterpictureinpicture", () => {
    setBrowserPipMode(true);
  });

  window.addEventListener("leavepictureinpicture", () => {
    setBrowserPipMode(false);
  });
  

  return (
    <>
      {playerData?.shows ? (
        <div className="liveSection">
          <h1>KICCTV Live</h1>
          <div className="containers">
            <div className="leftContainer">
              {position > 700 &&
                pipMode === true &&
                videoPlaying === true &&
                browserPipMode === false && (
                  <div className="thumbnailContainer">
                    <img
                      className="bg"
                      src={require("../../assets/images/live-page-bg.png")}
                      alt="Background"
                    />
                  </div>
                )}
              <div className="imageContainer">
                <div
                  className={
                    pipMode === true &&
                      position > 700 &&
                      browserPipMode === false &&
                      videoPlaying === true
                      ? "Vplayer pipMode"
                      : "Vplayer"
                  }
                >
                  <div className="closeIcon" onClick={() => setPipMode(false)}>
                    <Closeicon />
                  </div>
                  <VideoJS
                    options={videoJsOptions}
                    onReady={handlePlayerReady}
                  />
                </div>
              </div>
            </div>
            <div className="rightContainer" ref={rightContainerRef}>

              <RightContainer LiveData={playerData} param={param}/>
            </div>
          </div>
          <div className="buttonContainerBottom">
            <Link
              to="/live" 
              onClick={() => (document.documentElement.scrollTop = 0)}
            >
              <button>
                Go to Live
                <img
                  src={require("../../assets/images/play-icon.png")}
                  alt="Play-button"
                />
              </button>
            </Link>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default LiveSection;
