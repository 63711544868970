import React from 'react';
import "./about.css";

function About() {
  return (
    <div id="about">
        <h1>About</h1>
        <div className="content">
            <p>KICCTV reaches no less than 140 million homes across Europe, Africa and parts of the Middle East on three leading satellite platforms. In the UK and Ireland, KICCTV is available through Sky, the premier subscription television platform, offering a vast range of services to over 10.5 million active subscribers in the UK alone.</p>
        </div>
    </div>
  )
}

export default About