import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { service } from "../../../Network/Network";
import "./loginScreen.css";
import { ToastContainer, toast } from "react-toastify";

function LoginScreen() {
  const [ForgotPasswordFlag, setForgotPasswordFlag] = useState(false);
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [failMessage, setfailMessage] = useState("");
  const [values, setvalues] = useState({
    email: "",
    password: "",
  });
  const [forgotPswdvalues, setForgotPswdvalues] = useState({
    email: "",
  });
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setvalues({
      ...values,
      [name]: value,
    });
  };

  const passwordchangeHandler = (e) => {
    const { name, value } = e.target;
    setForgotPswdvalues({
      ...forgotPswdvalues,
      [name]: value,
    });
  };

  const validateEmail = (email) => {
    {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true;
      }
      return false;
    }
  };

  const validation = () => {
    let errors = {};
    let formIsValid = true;

    if (values.email.trim()) {
      var errorMsg = validateEmail(values.email);
      if (errorMsg === true) {
        errors.email = "";
        setEmail("");
      } else {
        formIsValid = false;
        setEmail(" Input--errored");
        errors.email = "Invalid email Address";
      }
    } else {
      formIsValid = false;
      errors.email = "Required Email Field";
      setEmail(" Input--errored");
    }
    if (values.password.trim()) {
      errors.password = "";
      setPassword("");
    } else {
      formIsValid = false;
      setPassword(" Input--errored");
      errors.password = "Required Password Field";
    }
    setErrors(errors);
    return formIsValid;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validation()) {
      service.login(values).then((response) => {
        if (response.data.success === true) {
          let loginData = response.data.data[0];
          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem("userName", loginData.first_name);
          localStorage.setItem("userId", loginData.user_id);
          service.setCookie("userEmail", loginData.user_email, 30);
          service.setCookie("userId", loginData.user_id, 30);
          service.setCookie("isLoggedIn", "true", 30);
          service.analytics().then((response) => {
            localStorage.setItem("deviceAnalytics", true);
          });
          setTimeout(() => {
                      window.location.href = "/";

          }, 3000);
        } else {
          setfailMessage(response.data.message);
        }
      });
    }
  };
  const passwordSubmitHandler = (e) => {
    e.preventDefault();
    if (forgotPswdvalues.email.trim()) {
      if (validateEmail(forgotPswdvalues.email)) {
        service.forgotEmail(forgotPswdvalues).then((response)=>{
          if (response.success === true) {
            toast.success("Reset Password Link has been sent to your Email successfully", {
              position: "top-center",
            })
          }
          else{
            toast.error("No user exist with this email address!", {
              position: "top-center",
            });
          }
        })
      } else {
        toast.error("You have entered an invalid email address!", {
          position: "bottom-left",
        });
      }
    }
    else{
      toast.error("Please Fill Email Field", {
        position: "bottom-left",
      });
    }
  };
  return (
    <div id="login">
      <h1 className="heading">Welcome to KICC TV</h1>
      <div className="loginContainer">
        <ToastContainer />
        <div className="wrapper">
          <div className="leftContainer">
            <div className="imageContainer">
              <img
                src={require("../../../assets/images/kicctv logo.png")}
                alt="Logo"
              />
            </div>
          </div>{" "}
          {!ForgotPasswordFlag ? (
            <div className="rightContainer">
              <h1>Login</h1>
              <form onSubmit={submitHandler} method="post">
                <div className="form-group">
                  <div className="oneByOne">
                    <label htmlFor="email" style={{ color: "red" }}>
                      {" "}
                      {failMessage}{" "}
                    </label>
                    <label htmlFor="email" style={{ color: "red" }}>
                      {" "}
                      {errors.email}{" "}
                    </label>
                    <label htmlFor="email">Email</label>
                    <input
                      value={values.email}
                      onChange={changeHandler}
                      type="email"
                      name="email"
                      id="email"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="oneByOne">
                    <label htmlFor="password" style={{ color: "red" }}>
                      {" "}
                      {errors.password}{" "}
                    </label>
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      value={values.password}
                      onChange={changeHandler}
                      name="password"
                      id="password"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="oneByOne">
                    <button type="submit">Login</button>
                  </div>
                </div>
              </form>
              <p>
                Don't have an account?{" "}
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(`/register`)}
                >
                  Register
                </span>
              </p>
              <p
                className="forgettenPassword"
                style={{ cursor: "pointer" }}
                onClick={() => setForgotPasswordFlag(true)}
              >
                Forgot password?
              </p>
            </div>
          ) : (
            <div className="rightContainer">
              <h1>Reset Password</h1>
              <form onSubmit={passwordSubmitHandler} method="post">
                <div className="form-group">
                  <div className="oneByOne">
                    <label htmlFor="email" style={{ color: "red" }}>
                      {" "}
                      {failMessage}{" "}
                    </label>
                    <label htmlFor="email" style={{ color: "red" }}>
                      {" "}
                      {errors.email}{" "}
                    </label>
                    <label htmlFor="email">Email</label>
                    <input
                      value={forgotPswdvalues.email}
                      onChange={passwordchangeHandler}
                      type="email"
                      name="email"
                      id="email"
                    />
                  </div>
                </div>

                {/* otp varification field */}

                <div className="form-group">
                  <div className="oneByOne">
                    <button type="submit">Submit </button>
                  </div>
                </div>
              </form>
              <p>
                Don't have an account?{" "}
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(`/register`)}
                >
                  Register
                </span>
              </p>
              <p
                className="forgettenPassword"
                style={{ cursor: "pointer" }}
                onClick={() => setForgotPasswordFlag(!forgotPswdvalues)}
              >
                Login
              </p>
            </div>
          )}
        </div>{" "}
        {/* ( .wrapper ) */}
      </div>{" "}
      {/* ( .loginContainer ) */}
    </div> // ( #login )
  );
}

export default LoginScreen;
