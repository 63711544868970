import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "./scheduleScreen.css";
import GetNextDates from "get-next-dates";
import { service } from "../../Network/Home/Service";
import dateFormat from "dateformat";
import { useNavigate } from "react-router-dom";
import ScheduleCard from "../LiveScreen/ScheduleCard";
import { convertTimeToLocal } from "../../Utils/utils";
function ScheduleScreen() {
  const [filterCount, setfilterCount] = useState(10)
  const [downArrow, setDownArrow] = useState(true);
  const [activeButton, setActiveButton] = useState(-1)
  let isLoggedIn = localStorage.getItem("isLoggedIn");
  let userId = service.getCookie("userId");
  const navigate = useNavigate();
  const [nextDays, setnextDays] = useState(GetNextDates(6));
  const todayDate = new Date();
  const [TodayFlag, setTodayFlag] = useState(true);
  const [EarlierShowFlag, setEarlierShowFlag] = useState(false);
  const [Schedule, setSchedule] = useState([]);
  const [showList, setShowList] = useState([]);

  const [triggerState, setTriggerState] = useState(false);
  const [targetTime, setTargetTime] = useState(showList[0]?.starttime);
  const [triggered, setTriggered] = useState(false);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // useEffect(() => {

  //   videoScheduleChange(); 
  // }, []);

  useEffect(() => {

    const currentTime = new Date();
    const videoStartTime = showList[0]?.starttime;
    const targetvideoTime = new Date(videoStartTime);
    const timeUntilTarget = targetvideoTime - currentTime;
    const timeUntilNextVideoInSeconds = timeUntilTarget / 1000;

    if (timeUntilNextVideoInSeconds > 0 && !triggered) {
      const timeoutId = setTimeout(() => {
        setTriggerState(true);
        setTriggered(true);
      }, timeUntilTarget);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [targetTime]);

  useEffect(() => {
    videoScheduleChange();
   
  },[triggerState]);
 

  useEffect(() => {
    if (!EarlierShowFlag) {
      scheduleChange(todayDate.toString().substring(4, 10));
    }
  }, [EarlierShowFlag]);

  useEffect(() => {
    if (filterCount >= showList.length) {
      setDownArrow(false);
        } else {
          setDownArrow(true);
        }
    
  }, [filterCount , showList])

  const loadMore = () => {
    setfilterCount(filterCount + 7)
  };
  const videoScheduleChange =() => {
  
    window.scrollTo(0, 0);
    setTimeout(()=>{
      service.getLiveSchedule(371).then((response) => {
        setSchedule(response.data);
        setTargetTime(response.data[0]?.endtime);
        let data = [];
        response.data.map((item, index) => {
          const itemStartrtTime = new Date(item.starttime);
          let videoDate = dateFormat(item.starttime, "mmm dd, yyyy")
            .toString()
            .substring(0, 6);
          if (
            videoDate === todayDate.toString().substring(4, 10) &&
            itemStartrtTime >= todayDate
          ) {
            data = [...data, item];
          }
          setShowList(data);
        });
      });
    },5000)


  }

  const scheduleChange = (requestDate) => {
    setfilterCount(10)
    setEarlierShowFlag(false)
    let data = [];
    Schedule.map((item, index) => {
      const itemStartrtTime = new Date(item.starttime);
      let videoDate = dateFormat(item.starttime, "mmm dd, yyyy")
        .toString()
        .substring(0, 6);
      if (videoDate === requestDate) {
        if (itemStartrtTime >= todayDate) {
          data = [...data, item];
        } else {
        }
      }
      setShowList(data);
    });
    if (todayDate.toString().substring(4, 10) === requestDate) {
      setTodayFlag(true);
    } else {
      setTodayFlag(false);
    }
  };

  const earlierShows = (requestDate) => {
    setfilterCount(10)
    let data = [];
    Schedule.map((item, index) => {
      const itemStartrtTime = new Date(item.starttime);
      let videoDate = dateFormat(item.starttime, "mmm dd, yyyy")
        .toString()
        .substring(0, 6);
      if (videoDate === requestDate) {
        if (itemStartrtTime < todayDate) {
          data = [...data, item];
        } else {
        }
      }
      setShowList(data);
    });
  };

  const remindMeHandler = (id, flag, index) => {
    if (userId && isLoggedIn) {
      service.scheduleReminder(id, flag).then((response) => {
        if (response) {
          let showlist = [...showList];
          showlist[index].schedule_reminded = flag ? false : true;

          setShowList(showlist);
        }
      });
    } else {
      navigate(`/login`);
    }
  };
 

    
  return (
    <div id="schedule">
      <div className="wrapper">
        <div className="scheduleContainer">
          <h1>KICCTV Schedule</h1>
          <div className="weeklyContainer">
            <Slider {...settings}>
              <div className="weeks">
                <button
                  className={activeButton === -1 ? "activeButtonBgChange weekDays" :"weekDays"}
                  onClick={() => {
                    setActiveButton(-1)
                    scheduleChange(todayDate.toString().substring(4, 10));
                    setEarlierShowFlag(false);
                  }}
                >
                  Today
                </button>
              </div>
              {nextDays &&
                nextDays.map((item, index) => (
                  <div className="weeks" key={index}>
                    <button
                        className={activeButton === index ? "activeButtonBgChange weekDays" :"weekDays"}
                        onClick={() => {
                        setActiveButton(index)

                        scheduleChange(item.toString().substring(4, 10));
                      }}
                    >
                      {item.toString().substring(0, 3) +
                        " " +
                        dateFormat(item, "dS")}
                    </button>
                  </div>
                ))}
            </Slider>
          </div>
          <div
            className="earlierShowsContainer"
            style={{ display: !TodayFlag && "none" }}
          >
            <button
              onClick={() => {
                setEarlierShowFlag(!EarlierShowFlag);
                earlierShows(todayDate.toString().substring(4, 10));
              }}
            >
              Earlier Shows
              <img
                style={{ transform: EarlierShowFlag && "scaleY(-1)" }}
                src={require("../../assets/images/arrow-down.png")}
                alt="Down-arrow"
              />
            </button>
          </div>
        </div>{" "}
        {/* ( scheduleContainer ) */}
      </div>{" "}
      {/* ( .wrapper ) */}
      <div id="allShows">
        <div className="wrapper">
          <div className="showsContainer">
            {/* Reusing schedule card from live screen */}
            {showList.length > 0
              ? showList?.filter((item , index)=>index < filterCount)?.map((item, index) => (
                EarlierShowFlag ? (
                  // index > 0 &&
                 ( <ScheduleCard
                    show={item}
                    key={index}
                    index={index}
                    remindFunction={remindMeHandler}
                    earlyFlag = {EarlierShowFlag}
                  />))
                  :
                  (<ScheduleCard
                    show={item}
                    key={index}
                    index={index}
                    remindFunction={remindMeHandler}
                    earlyFlag = {EarlierShowFlag}
                  />)
                ))
              : `No Scheduled Programs`}
          </div>{" "}
          <div className="bottom" style={{ display:!downArrow && 'none'}}>
            {(downArrow)&&
          <div
            className= "downArrowContainer"
            onClick={loadMore}
          >
            <img
              src={require("../../assets/images/down-arrow-flat.png")}
              alt="Down-arrow"
            />
          </div>
          }
        </div>
          {/* // ( showContainer ) */}
        </div>{" "}
        {/* // ( wrapper ) */}
      </div>{" "}
      {/* // ( allshows ) */}
    </div>
  );
}

export default ScheduleScreen;
