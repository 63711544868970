import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-contrib-ads';
import 'videojs-ima';
import 'videojs-sprite-thumbnails';// videoJs Thumbnail Plugin

export const VideoJS = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const {options, onReady} = props;

  
  let uId = localStorage.getItem("guestUserId");
  let user_id = localStorage.getItem("userId");
  var token = localStorage.getItem("access_token");
  if (user_id) {
    uId = user_id
  }

  videojs.Vhs.xhr.onRequest = function(options) {
    if(options?.uri.includes(".m3u8?id=")){
      options.headers = {
        pubid: process.env.REACT_APP_PUBID,
        channelid:process.env.REACT_APP_CHANNELID,
        uid:uId,
        "access-token":token
      };
    }
    return options;
  };

  React.useEffect(() => {

    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;

      if (!videoElement) return;

      const player = playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player);
      });
      if (props?.hoverThumbnail) {
        player.spriteThumbnails({
          url: props?.hoverThumbnail,
          width: 160,
          height: 90,
          interval: 1
        });

      }
    // You could update an existing player in the `else` block here
    // on prop change, for example:
    } else {
    //   const player = playerRef.current;

    //   player.autoplay(options.autoplay);
    //   player.src(options.sources);
    }
  }, [options]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;
   
    if (props?.adUrl) {
     
      var imaOptions = {
        adTagUrl: props?.adUrl
      };
      
      player.ima(imaOptions);


    }
    return () => {
      if (player && player.isReady_) {
        player.dispose();
        playerRef.current = null;

      }
    };
  }, []);

  return (
    <div data-vjs-player>
      <video ref={videoRef} id='show-player' className='video-js vjs-big-play-centered' />
    </div>
  );
}

export default VideoJS;