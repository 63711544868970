import React, { useState, useEffect } from "react";
import "./homePage.css";
import LiveSection from "./LiveSection";
import BannerSection from "./BannerSection";
import NewArrival from "./NewArrival";
import Featured from "./Featured";
import { service } from "../../Network/Home/Service";
import { useDispatch, useSelector } from "react-redux";
import Spinner from '../Spinner/Spinner';
import {Link} from "react-router-dom"

function HomePage() {
  const [category, setCategory] = useState([]);
  const login = useSelector((state) => state.login);
  const [LiveData, setLiveData] = useState([]);
  const [NewArrivalData, setNewArrivalData] = useState([]);
  const [FeaturedData, setFeaturedData] = useState([]);
  const [TopBanner, setTopBanner] = useState([]);
  const [LiveLink, setLiveLink] = useState("");
  const [loading, setLoading] = useState(true);
  const [liveChanged,setLiveChanged] = useState();
  let loadedRows = [];

  useEffect(() => {
    window.scrollTo(0, 0);
    var singleObj = [];
    setLoading(true)
    service.getshowsbyCategory().then((response) => {
      if (response.success === true && response.data.length > 0) {
        
        var data = response.data;
        data.map((item, index) => {
          singleObj.push(item);
          if (item.type === "LIVE") {
                        setLiveData(item);
            setLiveLink(item.shows[0].live_link);
          } else if (item.type === "TOP_BANNERS") {
            setTopBanner(item);
          } else if (item.type === "NEW_RELEASES") {
            setNewArrivalData(item);
          } else if (item.type === "FEATURED") {
            setFeaturedData(item);
          }
        });
        setCategory(singleObj);
        loadedRows = singleObj;
        setLoading(false)
      }
    });
  }, [login,liveChanged]);
  
  
 
  return (
    !loading?
    <div id="main">
      <div className="wrapper">
        <div className="spotlight">
          {/* ####################### Banner section ####################### */}
          <BannerSection Data={TopBanner} />
          {/* ####################### Live section ####################### */}
          <LiveSection
            Data={LiveData != null && LiveData}
            LiveLink={LiveLink != null && LiveLink}
            setLiveChanged={setLiveChanged}
            liveChanged={liveChanged}
          />
        </div>
        {/* ( spotlight section ends ) */}
        {/* ####################### NEW ARRIVALS ####################### */}
      </div>
        <NewArrival NewArrival={NewArrivalData} />
        {/* (Whats new section ends) */}
        {/* ####################### Featured ####################### */}
       <div className="wrapper">

        <Featured Data={FeaturedData} />
        <div className="buttonContainerBottom">
            <Link
              to="/on-demand"
              onClick={() => (document.documentElement.scrollTop = 0)}
            >
              <button>
                Go to On Demand
                <img
                  src={require("../../assets/images/play-icon.png")}
                  alt="Play-button"
                />
              </button>
            </Link>
          </div>
       </div>
      {/* (feature Section ends) */}
    </div>
    :

    <div id="main">
      <div className="wrapper">
      <div className="spotlight">
                    <div style={{display:'flex' , padding:'130px 0' ,  justifyContent: 'center' , alignItems:'center'}}><Spinner/></div>

      </div>

       </div>
    </div>


  );
}

export default HomePage;
