import React from 'react';
import "./privacyPolicy.css";

function PrivacyPolicy() {
  return (
    <div id='privacyPolicy'>
        <h1>Privacy Policy</h1>
        <div className="content">
            <div className="wrapper">
                <p>Kingsway International Christian Centre (KICC) Privacy Policy covers KICC Ltd’s use of the personally identifiable information that you provide and that we collect or hold, including when you continue to browse and use this website (www.kicctv.tv).</p>
                <p>If you continue to browse and use this website, you are agreeing to comply with and be bound by the following terms and conditions of use, which, govern KICC Ltd’s relationship with you. If you disagree with any part of this policy please do not provide personal information and do not use this website.</p>
                <h3>Limitations of liability</h3>
                <p className='end'>KICC will not be liable to you (whether under the law of contact, the law of torts or otherwise) in relation to the contents of, or use of, or otherwise in connection with, this website:</p>
                <h3>Data protection</h3>
                <p>This policy explains how KICC Ltd and www.kicctv.tv complies with the General Data Protection Regulation(GDPR), which comes into effect on 25 May 2018, and the Privacy and Electronic Communications Regulations. We may update this policy following the UK’s exit from the European Union.</p>
                <div className="listSection">
                    <h3>How we collect information about you</h3>
                    <p>We collect personal information each time you are in contact with us. For example, when you:</p>
                    <ul>
                        <li value="number">Visit our website</li>
                        <li value="number">Register yours and or other individual’s details at www.kicctv.tv</li>
                        <li value="number">Make a donation, by completion of Gift envelopes, via our website or electronic means</li>
                        <li value="number">Register for a conference or other Church event</li>
                        <li value="number">Provide your contact details, in writing or orally, to Church staff or volunteers</li>
                        <li value="number">Purchase goods or services, including when you provide credit or debit card details. Please note that KICC Ltd do not store credit card numbers</li>
                    </ul>
                </div>  {/* ( .listSection ) */}

                <div className="listSection">
                    <h3>How we use your information</h3>
                    <p>KICC Ltd will use the personal information we collect for the purpose disclosed at the time of collection, or otherwise as set out in this Privacy Policy. We will not use your personal information for any other purpose without first seeking your consent, unless authorised or required by law. Generally, we will only use and disclose your personal information as follows:</p>
                    <ul>
                        <li value="number">To keep you informed by text and/or email about church services, activities, resources and conferences</li>
                        <li>To establish and maintain your involvement with KICC Ltd. This is includes but is not limited to the events you have attended, what areas and activities of KICC Ltd you have supported, recorded and acknowledged donations, the products you have requested</li>
                        <li>To answer an enquiry or request for further information or complaint about KICC Ltd, its services, activities and events</li>
                        <li>To register you for events, conferences</li>
                        <li>To assist KICC Ltd. in in making its sites, services and products more valuable to its community</li>
                        <li>For promotion of products or services and to keep you informed of new developments we believe may be of interest to you. If we contact you in this way without obtaining your prior consent, we will provide you with the opportunity to decline any further promotional communications</li>
                        <li>To improve our general ability to assist Church attendees and the wider community</li>
                        <li>To assist KICC Ltd and Senior Management with management of issues relating to KICC Ltd</li>
                    </ul>
                </div>  {/* ( .listSection ) */}

                <h3>Who sees your information</h3>
                <p>The information you provide to us will be held on computers in the KICC Ltd Chatham, United Kingdom, and our service providers who act for us for the purposes set out in this policy or for other purposes approved by you.</p>
                <p>Those parties may process information, fulfil and deliver orders, process credit card payments and provide support services on our behalf.</p>
                <p>We do not sell or pass any of your personal information to any other organisations and/or individuals without your express consent, with the following exceptions:</p>
                <p>By providing us with your details you are giving KICC Ltd your express permission to transfer your data to our service providers including mailing houses, such as email delivery companies, to enable fulfilment of the purpose for collection.</p>
                <p>Where such details are shared we have confidentiality agreements in place that restrict the use of your information to the purpose for which it is provided and ensure it is stored securely and kept no longer than necessary.</p>
                <p>We do not currently store or intend to store financial details (e.g. credit or debit card numbers) obtained through online transactions. We do not store details online nor do we pass any information to third parties, except where we are legally required to do so, to assist fraud reduction, or to provide a service requested and minimise credit risks.</p>
                <p className="end">Sensitive Personal Information: KICC Ltd may collect and store sensitive personal information such as health information, religious information (church attendance) when you and/or your family attend, register for church events and conferences. Your personal information will be kept strictly confidential. It is never sold, given away, or otherwise shared with anyone, unless required, by law.</p>

                <h3>Keeping details up to date</h3>
                <p>Please tell us as soon as any of your contact details change so that we can keep our records up to date.</p>
                <p>You can change the way we contact you, or the kind of material we send you, at any time by contacting us by mail or by emailing using the contact details above or your contract preferences in your KICC website account.</p>
                <p>You can unsubscribe from our regular emails or texts at any time by using the ‘unsubscribe’ or ‘change preferences’ links on the email or texts you have received.</p>
                <p className='end'>If you register with kicctv.tv you should personally log-on and update your contact details.</p>
            
                <h3>Access to your information</h3>
                <p>You can request access to the personal information that KICC Ltd holds about you by contacting KICC Ltd’s Data Officer as set out below. We will provide you with access to your personal information unless we are legally authorised to refuse your request.</p>
                <p>If you wish to change personal information that is out of date or inaccurate at any time please contact us. KICC Ltd will take reasonable steps to correct any of your inaccurate, incomplete or out of date information. If you wish to have your personal information deleted please let us know and we will delete that information wherever practicable.</p>
                <p className='end'>We may refuse your request to access, amend or delete your personal information in certain circumstances. If we do refuse your request, we will provide you with a reason for our decision and, in the case of amendment, we will annotate on your personal information that you have disputed its accuracy.</p>
            
                <h3>Security</h3>
                <p>KICC Ltd will take reasonable steps to keep any personal information which we hold secure, accurate and up to date. Personal information, held electronically, is stored in a secure server or secure files.</p>
                <p className='end'>We take security measures to protect your information from access by unauthorised persons and against unlawful processing, accidental loss, destruction and damage. Your information will be held for a reasonable period or as long as the law requires or permits.</p>
            
                <h3>Cookies policy</h3>
                <p className='end'>Cookies are small amounts of information that we store on your computer. Unless you have indicated your objection when disclosing your details to us, our system will issue cookies to your computer when you log on to www.kicctv.tv. Cookies make it easier for you to log on to and use the site during future visits. They also help to monitor website traffic and personalise the content of the site for you but will not store, save or collect personal information. You may set up your computer to reject cookies although, in that case, you may not be able to use certain features on our site.</p>

                <h3>Links</h3>
                <p>This website may also include links to other websites. Links provided by KICC Ltd are for your convenience to provide further information.</p>
                <p>We use websites such as YouTube to embed videos on our website, service providers such email delivery companies and Google Analytics and you may be sent cookies from them via www.kicctv.tv. Please look at the cookie and privacy policies on these third-party sites if you want more information about this.</p>
                <p>Unless it is material supplied or posted by KICC Ltd we do not endorse the website(s) and have no responsibility for the content of the linked website(s), nor for the cookies they may contain.</p>
                <p className='end'>Users should therefore note they click on external links at their own risk and we cannot be held liable for any damages or implications caused by visiting any external links.</p>
            
                <h3>Social media</h3>
                <p>KICC Ltd uses social media such as YouTube. Users must verify the authenticity of sites before posting or providing personal information on such sites.</p>
                <p>Our website may provide social media buttons, permitting sharing our web content directly to a social media, platform. The use of such buttons is at your own risk.</p>
                <p>Unless it is material supplied or officially posted by KICC Ltd we do not endorse social media website(s) and have no responsibility for the content nor for the cookies they may contain.</p>
                <p className='end'>KICC Ltd does not ask for passwords nor personal details on social media.</p>
            
                <h3>Downloads</h3>
                <p className='end'>Any documents or files made available to download from our website are provided at the users own risk.</p>

                <h3>Changes to this policy</h3>
                <p className="end">KICC Ltd may amend this Privacy Policy from time to time to ensure compliance with changes or amendments to UK law. Any amended version will be available on our website at www.kicctv.tv/privacy-policy. We suggest that you visit our website regularly to keep up to date with any changes.</p>

                <h3>Contact details</h3>
                <p className="end">If you would like any further information, or have any queries, problems or complaints relating to KICC Ltd’s Privacy Policy or our information handling practices in general, please contact our Data Officer by Telephone :0208 5250000 email: data@kicctv.tv; Kingsway International Christian centre (KICC); Address : KICC, Buckmore Park, Maidstone Road, Chatham, Kent, ME5 9QG</p>

                <h3>Company details</h3>
                <p>Kingsway International Christian centre (KICC) Registered Charity 1102114; Telephone: 0208 5250000 email: data@kicctv.tv Address : KICC, Buckmore Park, Maidstone Road, Chatham, Kent, ME5 9QG</p>
            </div> {/* ( .wrapper ) */}
        </div> {/* ( .content ) */}
    </div>// ( #privacyPolicy )
  )
}

export default PrivacyPolicy