import { createSlice } from "@reduxjs/toolkit";
export const AccountSettingsSlice=createSlice({
    name:"AccountDetails",
    initialState:{
        value:{}
    },
    reducers:{
        getAccountDetails:(state,action)=>{
            state.value=action.payload.AccountDetails
        }
    }
})
export const {getAccountDetails}=AccountSettingsSlice.actions
export default AccountSettingsSlice.reducer