import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./User/userSlice";
import  accountReducer  from "./User/AccountSettingsSlice";
export default configureStore({
    reducer:{
        user:userReducer,
        AccountDetails:accountReducer
    }
})

