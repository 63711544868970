import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";

function Footer() {

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return (
    <footer>
      <div className="wrapper">

        <div className="leftSection">
          <div className="left">
            <div className="imageContainer">
              <img 
              
                src={require("../../assets/images/kicctv logo.png")}
                alt="Logo"
              />
            </div>
          </div>
          <div className="right">
            <ul className="social">
              <li onClick={()=>window.location.href=`https://www.instagram.com/watchkicctv/`}>
                <div className="iconIcontainer" >
                  <img
                  
                    src={require("../../assets/images/social icon - ig.png")}
                    alt="Instagram"
                  />
                </div>
              </li>
              <li onClick={()=>window.location.href=`https://twitter.com/watchKICCTV`}>
                <div className="iconIcontainer">
                  <img
                    src={require("../../assets/images/social icon - tw.png")}
                    alt="Twitter"
                  />
                </div>
              </li>
              <li onClick={()=>window.location.href=`https://www.youtube.com/user/KICCOnline`}>
                <div className="iconIcontainer">
                  <img
                    src={require("../../assets/images/social icon - yt.png")}
                    alt="Youtube"
                  />
                </div>
              </li>
              <li onClick={()=>window.location.href=`https://www.facebook.com/watchKICCTV`}>
                <div className="iconIcontainer">
                  <img
                    src={require("../../assets/images/social icon - fb.png")}
                    alt="Facebook"
                  />
                </div>
              </li>
            </ul>
            <ul className="links">
              <Link onClick={()=>document.documentElement.scrollTop=0} to="/about">
                <li>About</li>
              </Link>
              <Link onClick={()=>document.documentElement.scrollTop=0} to="/contact-us">
              <li>Contact Us</li>
              </Link>
              <Link onClick={()=>document.documentElement.scrollTop=0} to="/privacy-policy">
              <li>Privacy Policy</li>
              </Link>
              <Link onClick={()=>document.documentElement.scrollTop=0} to="/terms-conditions">
              <li>Terms and Conditions</li>
              </Link>
            </ul>
          </div>
        </div>
        {/* ( div.leftSection ) */}
        <div className="rightSection">
          <h2>Download the App</h2>
          <div className="storeContainer">
           <div className="logoContainer" title="IOS" onClick={()=>window.open("https://apps.apple.com/lb/app/kicctv-live/id1603445865","_blank")}>
            <img src={require("../../assets/images/2.png")} alt="Logo" />
           </div>
           <div className="logoContainer" title="Android" onClick={()=>window.open(" https://play.google.com/store/apps/details?id=com.kicctv","_blank")}>
            <img src={require("../../assets/images/4.png")} alt="Logo" />
           </div>
           <div className="logoContainer" title="Apple Tv" onClick={()=>window.open("https://apps.apple.com/lb/app/kicctv-live/id1603445865?platform=appleTV","_blank")}>
            <img src={require("../../assets/images/1.png")} alt="Logo" />
           </div>
           <div className="logoContainer " title="Google Tv" onClick={()=>window.open("https://play.google.com/store/apps/details?id=com.kicctv","_blank")}>
            <img src={require("../../assets/images/3.png")} alt="Logo" />
           </div>
           <div className="logoContainer" title="Roku TV">
            <img src={require("../../assets/images/5.png")} alt="Logo" onClick={()=>window.open("https://channelstore.roku.com/en-ca/details/d0b176ab37526cb97a516f1fce3d114d/kicctv","_blank")} />
           </div>
           <div className="logoContainer" title="Fire TV">
            <img src={require("../../assets/images/6.png")} alt="Logo" onClick={()=>window.open("https://www.amazon.com/Broadcast-Systems-Europe-Ltd-KICCTV/dp/B09Z6Z37N3","_blank")} />
           </div>
          </div>
          <div className="copyrightContainer">
            {currentYear} © KICC All Rights Reserved
            <p>
              KICC TV
              <br />
              Company Name: KICC
              <br />
              Buckmore Park, Maidstone, Chatham, Kent, ME5 9QG
              <br />
              +44(0)208 5250000
              <br />
              <a href="mailto:kicctvenquires@kicc.org.uk">
                kicctvenquires@kicc.org.uk
              </a>
              <br />
              Technical Support
              <br />
              +44(0)208 5250000
              <br />
              <a href="mailto:kicctvsupport@kicc.org.uk">
                kicctvsupport@kicc.org.uk
              </a>
            </p>
          </div>
        </div>
        {/* ( div.rightSection ) */}
      </div>
    </footer>
  );
}

export default Footer;
