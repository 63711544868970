import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { service } from "../../Network/Home/Service";
import Spinner from "../Spinner/Spinner";
import "./onDemandScreen.css";
import ShowSlider from "./ShowSlider";

function OnDemandScreen() {
  const navigate = useNavigate();
  const [shows, setshows] = useState([]);
  const [Featured, setFeatured] = useState([]);

    // ######################################## used in lazy loading ########################################

  // lazy loading vars
  // let offset = 0;
  // let scrollHeight = 100;
  // let maxScrollExceed = false;
  // let loadedRows = [];
  // var singleObj = [];

  var settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  useEffect(() => {
    service.getOndemandshowsbyCategory().then((response) => {
      if (response) {
        response.data.map((item, key) => {
          if (item?.type === "ON-DEMAND_BANNERS") {
            setFeatured(item?.shows);
          }
        });
      }

      setshows(response.data);
    });
  }, []);


    // ######################################## used in lazy loading ########################################

  // useEffect(() => {
  //   let prevPosition = 0;
  //   let newPosition = 0;
  //   let currentPosition = 0;
  //   window.addEventListener("scroll", (e) => {
  //     newPosition = window.pageYOffset;
  //     currentPosition += 1;
  //     if (
  //       !maxScrollExceed &&
  //       prevPosition < newPosition &&
  //       currentPosition > scrollHeight
  //     ) {
  //       currentPosition = 0;
  //       offset += 10;
  //       fetchData();
  //     } else if (prevPosition > newPosition) {
  //     }
  //     prevPosition = newPosition;
  //   });
  // }, []);

  // ######################################## used in lazy loading ########################################

  // const fetchData = async () => {
  //   setTimeout(async () => {
  //     service.getOndemandshowsbyCategory(offset).then((response) => {
  //       if (response.success === true && response.data.length > 0) {
  //         var data = response.data;
  //         let singleObj = [];
  //         data.map((item, index) => {
  //           singleObj.push(item);
  //         });
  //         loadedRows = [...shows, ...singleObj];
  //         console.log(`loaded rows new`, loadedRows);
  //         setshows(loadedRows);
  //       } else if (response.data.length == 0) {
  //         maxScrollExceed = true;
  //       }
  //     });
  //   }, 1000);
  // };

  

  const addToMyList = (id, index) => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    let userId = service.getCookie("userId");
    if (isLoggedIn && userId) {
      service.addToMyPlayList(id, 1).then((response) => {
        if (response.success === true) {
          let showlist = [...Featured];
          showlist[index].watchlist_flag = 1;

          setFeatured(showlist);
        }
      });
    } else {
      navigate(`/login`);
    }
  };
  const removeFromMyList = (id, index) => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    let userId = service.getCookie("userId");
    if (isLoggedIn && userId) {
      service.addToMyPlayList(id, 0).then((response) => {
        if (response.success === true) {
          let showlist = [...Featured];
          showlist[index].watchlist_flag = 0;

          setFeatured(showlist);
        }
      });
    } else {
      navigate(`/login`);
    }
  };

  return (
    <div id="onDemand">
      {/* ################################## banner ################################## */}
        <div className="wrapper">
          <div id="features">
            <Slider className="featureSection" {...settings2}>
              {Featured.map((item, index) => (
                <div className="items" key={index}>
                   <div className="background">
              <img
                style={{background:"black",opacity:".55"}}
                src={item?.logo_thumb}
                alt="Thumbnail"
              />
            </div>
            <div className="containers">
              <div className="leftContainer">
                <div className="imageContainer">
                <img
                    src={item?.logo_thumb}
                    alt="Thumbnail"
                  />
                </div>
              </div>
              <div className="rightContainer">
              <h1 className="title">{item?.show_name}</h1>
              <p>{item?.synopsis ? item?.synopsis : item?.show_name}</p>
                <div className="buttonContainer">
                <button
                        onClick={() => {
                          navigate({
                            pathname: "/show",
                            search: encodeURI(`show_id=${item.show_id}`),
                          });
                        }}
                      >
                        Play
                        <img
                          src={require("../../assets/images/play-icon.png")}
                          alt="PlayIcon"
                        />
                      </button>
                      {item.watchlist_flag === 1 ? (
                        <button
                          onClick={() => {
                            removeFromMyList(item?.show_id, index);
                          }}
                          className="removeList"
                        >
                          Remove from List
                          <img
                        src={require("../../assets/images/minus.svg").default}
                        alt="CrossIcon"
                      />
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            addToMyList(item?.show_id, index);
                          }}
                        >
                          Add to List
                          <img
                            src={require("../../assets/images/icon-plus.png")}
                            alt="PlusIcon"
                          />
                        </button>
                      )}
                </div>
              </div>
            </div>
                </div>
                // <div key={index} >
                // <div className="items" style={{backgroundImage:`url("${item?.logo_thumb}")`}}>
                //   <div className="leftContainer">
                //     <div className="imageContainer" >
                //       <img src={item?.logo_thumb} alt="Thumbnail" />
                //     </div>
                //   </div>
                //   <div className="rightContainer">
                //     <h1 className="heading">{item?.show_name}</h1>
                //     <p>{item?.synopsis ? item?.synopsis : item?.show_name}</p>
                //     <div className="buttonContainer">
                //       <button
                //         onClick={() => {
                //           navigate({
                //             pathname: "/show",
                //             search: encodeURI(`show_id=${item.show_id}`),
                //           });
                //         }}
                //       >
                //         play
                //         <img
                //           src={require("../../assets/images/play-icon.png")}
                //           alt="PlayIcon"
                //         />
                //       </button>
                //       {item.watchlist_flag === 1 ? (
                //         <button
                //           onClick={() => {
                //             removeFromMyList(item?.show_id, index);
                //           }}
                //           className="removeList"
                //         >
                //           Remove from List
                //           <img
                //         src={require("../../assets/images/close-white.png")}
                //         alt="CrossIcon"
                //       />
                //         </button>
                //       ) : (
                //         <button
                //           onClick={() => {
                //             addToMyList(item?.show_id, index);
                //           }}
                //         >
                //           Add to List
                //           <img
                //             src={require("../../assets/images/icon-plus.png")}
                //             alt="PlusIcon"
                //           />
                //         </button>
                //       )}
                //     </div>
                //   </div>
                // </div>
                // </div>
              ))}
            </Slider>
          </div>{" "}
          {/* ( #features ) */}
        </div>
      {/* ################################## catagories ################################## */}
      {shows.length > 0 ? shows.map(
        (item, index) =>
          !(
            item?.type === "LIVE" ||
            item?.type === "FEATURED" ||
            item?.type === "ON-DEMAND_BANNERS" ||
            item?.type === "TOP_BANNERS"

          ) && (
            <div className="whatsNewSection" key={index}>
              <ShowSlider showName={item?.category_name} shows={item} />
            </div>
          )
      )
    :
    <div style={{display:'flex' , justifyContent: 'center' , alignItems:'center' , marginTop: '100px'}}><Spinner/></div>
  }

    </div> // ( #onDemand )
    
  );
}

export default OnDemandScreen;
