import React, { useState } from "react";
import { service } from "../../Network/Network";
import "./contactUs.css";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { ToastContainer, toast } from "react-toastify";

function ContactUs() {
  const [imageName, setImageName] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [captchaFlag, setcaptchaFlag] = useState(false);

  const [values, setValues] = useState({
    topic: "Advertising Enquiries",
    message: "",
    file: [],
  });

  const SITE_KEY = "6Ldvi7kiAAAAAHYIK71Ui00zOyab772WJmA2wOt5";

  const getImage = (imageName) => {
    let name = imageName.replace(/^.*\\/, "");
    setImageName(name);
  };

  const wordLength = (length) => {
    let wordLen = 1000 - length;
    setWordCount(wordLen);
  };

  const handleFileChange = (e) => {
    const name = e?.target?.files[0]?.name;
    const files = e?.target?.files;
    setImageName(name);

    setValues({
      ...values,
      file: files[0],
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (captchaFlag) {
      service.contact(values).then((response) => {
        if (response.success) {
          toast.success("Sent successfully", { position: "bottom-left" });
          setValues({
            topic: "Advertising Enquiries",
            message: "",
            file: [],
          });
        } else {
          toast.error("Something went wrong", { position: "bottom-left" });

        }
      });
    }else{
      toast.error("Captcha failed", { position: "bottom-left" });

    }
  };

  const captchaChange = () => {
    setcaptchaFlag(true);
  };

  return (
    <div id="contact-us">
            <ToastContainer />

      <h1 className="heading">Contact Us</h1>
      <div className="content">
        <div className="wrapper">
          <div className="formContainer">
            <form onSubmit={(e) => submitHandler(e)} method="post">
              <div className="subject">
                <select
                  className="form-control"
                  name="subject"
                  onChange={(e) => {
                    setValues({
                      ...values,
                      topic: e.target.value,
                    });
                  }}
                >
                  <option value="Advertising Enquiries">
                    Advertising Enquiries
                  </option>
                  <option value="Programme Enquiries">
                    Programme Enquiries
                  </option>
                  <option value="Website Issues">Website Issues</option>
                  <option value="Prayers">Prayers</option>
                  <option value="Testimonies">Testimonies</option>
                  <option value="Music Video Submissions">
                    Music Video Submissions
                  </option>
                  <option value="Programme Feedback">Programme Feedback</option>
                  <option value="Work Experience &amp; Traineeships">
                    Work Experience &amp; Traineeships
                  </option>
                </select>
              </div>
              <div className="textArea">
                <textarea
                value={values.message}
                  name="message"
                  maxLength="1000"
                  id=""
                  required
                  style={{ height: "290px" }}
                  onChange={(e) => {
                    wordLength(e.target.value.length);
                    setValues({
                      ...values,
                      message: e.target.value,
                    });
                  }}
                ></textarea>
              </div>
              <div className="textAreaDetails">
                <div className="left">
                  <p className={imageName ? "imageName" : "no-active"}>
                    {imageName}
                  </p>
                  <span>Word limit : </span>
                  <span>{wordCount}/1000</span>
                </div>
                <div className="right">
                  <label htmlFor="file">
                    <div className="button">
                      <img
                        src={require("../../assets/images/icon-clip.png")}
                        alt="FileIcon"
                      />
                    </div>
                  </label>
                  <input
                    type="file"
                    id="file"
                    onChange={(e) => handleFileChange(e)}
                  />
                </div>
              </div>{" "}
              {/* ( .textAreaDetails ) */}
              <div className="captcha">
                <GoogleReCaptchaProvider reCaptchaKey={SITE_KEY}>
                  <GoogleReCaptcha onVerify={captchaChange} />
                </GoogleReCaptchaProvider>
              </div>
              <div className="submit">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>{" "}
          {/* ( .formContainer ) */}
        </div>{" "}
        {/* ( .wrapper ) */}
      </div>
      {/* ( .content ) */}
    </div> // ( #contact-us )
  );
}

export default ContactUs;
