import React, { useEffect, useState } from "react";
import "./sundayLiveScreen.css";
import Countdown from "react-countdown";
import VideoJS from "../../components/Videojs/VideoJS";
import { service } from "../../Network/Home/Service";
function SundayLiveScreen() {
  const [NextDate, setNextDate] = useState();
  const [videoPlayer, setVideoPlayer] = useState();
  const [playerActive, setplayerActive] = useState(false);
  const [showList, setShowList] = useState([]);
  const [videoJsOptions, setVideoJsOptions] = useState({
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: ``,
        type: "application/x-mpegURL",
      },
    ],
  });
  var todayDate = new Date();

  useEffect(() => {
    service.getSundayLive().then((response) => {
      var data = response.data;
      setShowList(data);
      setNextDate(nextDateSelector(data));
    });
  }, []);




  const nextDateSelector = (data) => {
    var nextItemSet = data.filter((item) => new Date(item.schedule_time) > todayDate);
    nextItemSet.length > 0 && (nextItemSet = nextItemSet.sort((a, b) => new Date(a.schedule_time) > new Date(b.schedule_time) ? 1 : -1))
    var prevItemSet = data.filter((item) => new Date(item.schedule_time) <= todayDate);
    prevItemSet.length > 0 && (prevItemSet = prevItemSet.sort((a, b) => new Date(a.schedule_time) > new Date(b.schedule_time) ? 1 : -1))
    todayDate = new Date();
    if (new Date(prevItemSet[prevItemSet.length - 1]?.end_time) > todayDate) {
      let linkToLIve = prevItemSet[prevItemSet.length - 1].live_url;
      var endTime = new Date(prevItemSet[prevItemSet.length - 1].end_time)
      var endTimeSeconds = (endTime.getTime() / 1000) - (todayDate.getTime() / 1000)
      setTimeout(() => {

        setNextDate(nextDateSelector(data));
      }, endTimeSeconds +3000);

      let options = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [
          {
            src: prevItemSet[prevItemSet.length - 1].live_url,
            type: "application/x-mpegURL",
          },
        ],
      }
      setVideoPlayer(null);
      if (prevItemSet[prevItemSet.length - 1].type === "YOUTUBE") {
        playerChange(linkToLIve, prevItemSet[prevItemSet.length - 1].type);
      }
      else {
        playerChange(options, prevItemSet[prevItemSet.length - 1].type)
      }

      return null;
    } else {
      setplayerActive(false)
      return nextItemSet[0]?.schedule_time;
    }
  };

  const playerChange = (option, type) => {
    if (type === "YOUTUBE") {
      let link = option.replace("/watch?v=", "/embed/") + "?autoplay=1"
      setplayerActive(true);
      setVideoPlayer(<div id="sundayLive" style={{ height: "1000px", width: "1080px" }}><iframe
        style={{ width: "100%", height: "100%" }}
        src={link}
        frameBorder='0'
      /></div>);

    }
    else {
      setplayerActive(true);
      setVideoPlayer(<div id="sundayLive">{<VideoJS options={option} />}</div>);
    }


  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    // Render a countdown
    if (completed) {
      counterComplete();
    } else {
      return (
        <div className="timer">
          <div className="timer-div">
            <span> {isNaN(days) ? " " : days} </span>
            <span className="days">days</span>
          </div>
          <div className="timer-div">
            <span>{isNaN(hours) ? " " : hours}</span>
            <span className="days">hours</span>
          </div>
          <div className="timer-div">
            <span>{isNaN(minutes) ? " " : minutes}</span>
            <span className="days">mins</span>
          </div>
          <div className="timer-div">
            <span>{isNaN(seconds) ? " " : seconds}</span>
            <span className="days">secs</span>
          </div>
        </div>
      );
    }
  };

  const counterComplete = () => {
    setplayerActive(true)
    setTimeout(() => {
      nextDateSelector(showList);
    }, 1000);

  };

  return (
    <>

      <div style={{ display: playerActive ? "flex" : 'none', justifyContent: "center", alignItems: "center" }}>{videoPlayer}</div>

      <div id="sundayLive" style={{ display: playerActive ? 'none' : 'block' }}>
        <div className="wrapper">
          <div className="leftContainer">
            <div className="logoContainer">
              <img
                src={require("../../assets/images/kicctv logo.png")}
                alt="Logo"
              />
            </div>
          </div>{" "}
          <div className="rightContainer">
            <h1>Live Event</h1>

            <p>
              Morning Glow Live with Pastor Matthew Ashimolowo - tune in
              weekdays 6-7am (UK time) for prayer and prophetic confession.
            </p>
            <br />
            <p>
              Sunday Live events from KICC Prayer City - don’t miss church on
              Sundays, worship with KICC wherever you are.
            </p>
            <div className="timer">
              <div className="timer-div">
                <Countdown
                  renderer={renderer}
                  date={new Date(NextDate)}
                  onComplete={counterComplete}
                />
              </div>
            </div>
          </div>
        </div>{" "}
      </div>

    </>
  );
}

export default SundayLiveScreen;
