import React,{useEffect, useState} from 'react';
import "./allShows.css";


function AllShows() {
  const [sliceCount,setSliceCount] = useState(12);
  const [downArrow,setDownArrow] = useState(true)
  const [showsList,setShowsList] = useState(
    [
      {
        name:"Show Title",
        image:require("../../../assets/images/carousel thumbnail.png"),
      },
      {
        name:"Show Title",
        image:require("../../../assets/images/carousel thumbnail.png"),
      },
      {
        name:"Show Title",
        image:require("../../../assets/images/carousel thumbnail.png"),
      },
      {
        name:"Show Title",
        image:require("../../../assets/images/carousel thumbnail.png"),
      },
      {
        name:"Show Title",
        image:require("../../../assets/images/carousel thumbnail.png"),
      },
      {
        name:"Show Title",
        image:require("../../../assets/images/carousel thumbnail.png"),
      },
      {
        name:"Show Title",
        image:require("../../../assets/images/carousel thumbnail.png"),
      },
      {
        name:"Show Title",
        image:require("../../../assets/images/carousel thumbnail.png"),
      },
      {
        name:"Show Title",
        image:require("../../../assets/images/carousel thumbnail.png"),
      },
      {
        name:"Show Title",
        image:require("../../../assets/images/carousel thumbnail.png"),
      },
      {
        name:"Show Title",
        image:require("../../../assets/images/carousel thumbnail.png"),
      },
      {
        name:"Show Title",
        image:require("../../../assets/images/carousel thumbnail.png"),
      },
      {
        name:"Show Title",
        image:require("../../../assets/images/carousel thumbnail.png"),
      },
      {
        name:"Show Title",
        image:require("../../../assets/images/carousel thumbnail.png"),
      },
      {
        name:"Show Title",
        image:require("../../../assets/images/carousel thumbnail.png"),
      },
      {
        name:"Show Title",
        image:require("../../../assets/images/carousel thumbnail.png"),
      },
      {
        name:"Show Title",
        image:require("../../../assets/images/carousel thumbnail.png"),
      },
      {
        name:"Show Title",
        image:require("../../../assets/images/carousel thumbnail.png"),
      },
      {
        name:"Show Title",
        image:require("../../../assets/images/carousel thumbnail.png"),
      },
      {
        name:"Show Title",
        image:require("../../../assets/images/carousel thumbnail.png"),
      },
      {
        name:"Show Title",
        image:require("../../../assets/images/carousel thumbnail.png"),
      },
      {
        name:"Show Title",
        image:require("../../../assets/images/carousel thumbnail.png"),
      },
      {
        name:"Show Title",
        image:require("../../../assets/images/carousel thumbnail.png"),
      },
      
    ]
  );

  const slice = showsList.slice(0,sliceCount);
    useEffect(()=>{
      if(sliceCount>=showsList.length){
        setDownArrow(false)
      }
    },[sliceCount])
  const loadMore=()=>{
    if(sliceCount<showsList.length){
      setSliceCount(sliceCount+sliceCount);
    }
    if(sliceCount>=showsList.length || showsList.length-1==sliceCount || showsList.length-2==sliceCount || showsList.length-3==sliceCount || showsList.length-4==sliceCount){
      setDownArrow(false)
    }
  }


  const listShows=()=>{
    return slice.map((item,key)=>(
      <li key={key}>
        <div className="imageContainer">
          <img src={item.image} alt="Shows" />
        </div>
        <span>{item.name}</span>
      </li>
    ))
  }
  return (
    <div id='allShows'>
      <div className="wrapper">
        <h1 className="heading">All Shows (A-Z)</h1>
        <ul>
          {listShows()}
        </ul>
        <div className={downArrow?"bottom":"no-active"}>
          <div className={downArrow?"downArrowContainer":"no-active"} onClick={loadMore} >
            <img src={require("../../../assets/images/down-arrow-flat.png")} alt="Down-arrow" />
          </div>
        </div>
        </div>
    </div>
  )
}

export default AllShows;